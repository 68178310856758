.outro-order {
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: scale(0.25);

  &.display {
    opacity: 1;
    transform: scale(1);
    transition: all 0.8s ease-in;

    > .pdf-cover-block {
      -webkit-animation: pdf-pulse 5s linear 2s infinite;
      animation: pdf-pulse 5s linear 2s infinite;
    }
  }

  > .pdf-cover-block {
    position: absolute;
    width: 100%;
    max-width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto 0;

    &.hidden {
      opacity: 0;
      visibility: hidden;
      transition: all 0.8s ease-out;
    }

    > img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }

    .pdf-cover-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 18px;
      left: 14px;
      width: calc(100% - 60px);
      height: calc(100% - 68px);
      padding: 1rem;

      .title {
        font-size: 30px;
        font-weight: 900;
        line-height: 1.1;
        text-align: center;
        text-transform: uppercase;
        word-break: break-word;

        &.name-text {
          color: #eb28c0;
          text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
            1px 1px 0 #fff;
          margin-top: 85px;
        }
      }
    }

    @media (max-width: 768px) {
      margin: -30px 0 0;
      transform: scale(0.7);
    }

    @media (min-width: 1025px) and (max-height: 720px) {
      margin: 0;
      transform: scale(0.7);
    }
  }

  .order-form-block {
    visibility: hidden;
    opacity: 0;
    transform: translateY(150px);

    &.display {
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.8s ease-in 1s;
    }
  }

  @media (max-width: 1024px) {
    &.content-pdf-cover {
      max-height: calc(100vh - 171px);
    }

    .order-form-block {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 480px) {
    .order-form-block {
      margin-bottom: 1rem;
    }
  }
}

@-webkit-keyframes pdf-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pdf-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  @-webkit-keyframes pdf-pulse {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
  }
  @keyframes pdf-pulse {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
  }
}

@media (min-width: 1025px) and (max-height: 720px) {
  .outro-order {
    margin-bottom: 2rem;
  }

  .outro-order + .audio-caption-wrapper {
    bottom: 110px;
  }

  @-webkit-keyframes pdf-pulse {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
  }
  @keyframes pdf-pulse {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
  }
}
