.numbers-report {
  position: relative;
  width: 100%;
  height: 420px;
  opacity: 0;
  -webkit-animation: graphic-entrance 4s linear;
  animation: graphic-entrance 4s linear;

  &.entered {
    opacity: 1;
  }

  &.finished {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    transition: all 0.5s ease-out;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 1s ease-in;

    &.display {
      opacity: 1;
      transition-delay: 1s;
    }
  }

  img {
    position: absolute;
  }

  .report-number {
    margin: 2rem 0;

    img:nth-child(1) {
      width: 420px;
      top: 10px;
    }
    img:nth-child(2) {
      width: 420px;
      top: 0;
      opacity: 0.7;
    }
    img:nth-child(3) {
      width: 400px;
      top: 15px;
      margin-right: 5px;
    }
    img:nth-child(4) {
      width: 368px;
      top: 22px;
      margin-right: 5px;
      -webkit-animation: horizontal-spinning 6s linear 6s both infinite;
      animation: horizontal-spinning 6s linear 6s both infinite;
    }
    img:nth-child(5) {
      width: 230px;
      opacity: 0.4;
      -webkit-animation: shine-rotate 5s linear 6s both infinite;
      animation: shine-rotate 5s linear 6s both infinite;
    }

    .destiny-number {
      font-family: 'Pelmeshka';
      font-size: 60px;
      margin-top: 30px;
      text-shadow: 0px 6.34092px 52.3126px #ffc749;
      opacity: 0;
      z-index: 1;
      -webkit-animation: number-scale-up-down 4s linear 6s both infinite;
      animation: number-scale-up-down 4s linear 6s both infinite;
      transition: opacity 1s ease-in;

      &.display {
        opacity: 1;
      }
    }
  }

  .destiny {
    margin: 3rem 0;

    img:nth-child(1) {
      width: 420px;
      -webkit-animation: rotate-graph 25s linear both infinite;
      animation: rotate-graph 25s linear both infinite;
    }
  }

  @media (max-width: 480px) {
    height: calc(100vw - 86px);

    .report-number {
      width: calc(100% - 4rem);
      margin: 2rem;

      img:nth-child(1) {
        width: 100%;
      }
      img:nth-child(2) {
        width: 100%;
      }
      img:nth-child(3) {
        width: calc(100% - 20px);
        top: 12px;
      }
      img:nth-child(4) {
        width: calc(100% - 45px);
        top: 18px;
      }
      img:nth-child(5) {
        width: 50%;
      }

      .destiny-number {
        font-size: 80px;
        margin-top: 15px;
      }
    }

    .destiny {
      img:nth-child(1) {
        width: calc(100% - 4rem);
        max-width: 320px;
        margin: auto;
      }
    }
  }

  @media (min-width: 481px) and (max-height: 720px) {
    height: 350px;

    .report-number {
      margin: 1rem 0;

      img:nth-child(1) {
        width: 330px;
      }
      img:nth-child(2) {
        width: 350px;
      }
      img:nth-child(3) {
        width: 328px;
      }
      img:nth-child(4) {
        width: 300px;
        top: 21px;
      }
      img:nth-child(5) {
        width: 180px;
      }
    }

    .destiny {
      margin: 2rem 0;

      img:nth-child(1) {
        width: 340px;
      }
    }
  }
}

body {
  .background-images {
    opacity: 0;
    transition: all 1s ease-out;
  }
}

body.switch-background {
  .App {
    &::before {
      opacity: 0;
    }
  }

  .background-animation {
    opacity: 0;
  }

  .background-images {
    opacity: 1;
  }
}

@-webkit-keyframes graphic-entrance {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }
  75% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes graphic-entrance {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }
  75% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes number-scale-up-down {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes number-scale-up-down {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes shine-rotate {
  0% {
    -webkit-transform: scale(1.25) rotate(0deg);
    transform: scale(1.25) rotate(0deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1) rotate(30deg);
    transform: scale(1) rotate(30deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.25) rotate(0deg);
    transform: scale(1.25) rotate(0deg);
    opacity: 0.5;
  }
}
@keyframes shine-rotate {
  0% {
    -webkit-transform: scale(1.25) rotate(0deg);
    transform: scale(1.25) rotate(0deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1) rotate(30deg);
    transform: scale(1) rotate(30deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.25) rotate(0deg);
    transform: scale(1.25) rotate(0deg);
    opacity: 0.5;
  }
}

@-webkit-keyframes horizontal-spinning {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  50% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes horizontal-spinning {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  50% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes rotate-graph {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-graph {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-invert-graph {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes rotate-invert-graph {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media (max-width: 768px) {
  @-webkit-keyframes number-scale-up-down {
    0% {
      font-size: 110px;
    }
    50% {
      font-size: 80px;
    }
    100% {
      font-size: 110px;
    }
  }
  @keyframes number-scale-up-down {
    0% {
      font-size: 110px;
    }
    50% {
      font-size: 80px;
    }
    100% {
      font-size: 110px;
    }
  }
}

@media (max-width: 480px) {
  .numbers-report {
    margin-bottom: 11rem;
  }

  @-webkit-keyframes number-scale-up-down {
    0% {
      font-size: 80px;
    }
    50% {
      font-size: 60px;
    }
    100% {
      font-size: 80px;
    }
  }
  @keyframes number-scale-up-down {
    0% {
      font-size: 80px;
    }
    50% {
      font-size: 60px;
    }
    100% {
      font-size: 80px;
    }
  }
}
