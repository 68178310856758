.decade-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.125rem;
  row-gap: 1.25rem;

  @media (max-width: 1280px) {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    column-gap: 0.75rem;
    row-gap: 0.75rem;
  }
}
