.quiz-birthday {
  transition: all 0.5s ease-out;

  &.hidden {
    opacity: 0;
    transform: scale(0.25);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: 0 auto 1rem;

    > * {
      text-align: center;
    }

    p.h1 {
      margin-bottom: 1.25rem;

      @media (max-width: 768px) {
        padding: 0 1rem;
      }

      @media (max-width: 480px) {
        font-size: 20px;
        line-height: 1.3;
        padding: 0;
        margin-bottom: 0;
      }
    }

    p.h2 {
      font-weight: 600;
      line-height: 1;
      margin-top: 1rem;
      margin-bottom: 0.75rem;
    }
  }

  .step-content {
    position: relative;
    transition: opacity 0.2s ease-in-out;

    &.visible {
      opacity: 1;
      z-index: 99;
      transition-delay: 0.25s;
    }
    &.invisible {
      height: 0;
      opacity: 0;
      z-index: -1;
    }

    p.h2 {
      @media (max-width: 480px) {
        font-size: 18px;
        line-height: 1.3;
      }
    }
  }

  .number-box {
    &__inner {
      display: flex;
      justify-content: center;
      padding: 2rem 0;

      @media (max-width: 1280px) {
        padding: 1.75rem 0;
      }

      @media (max-width: 768px) {
        padding: 1.5rem 0;
      }

      @media (max-width: 480px) {
        padding: 0.75rem 0;
      }
    }
  }

  @media (max-width: 480px) {
    .box__wrapper {
      padding: 0.5rem;
    }

    .box__inner {
      padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    }
  }
}

@-webkit-keyframes scale-up-top {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0.25);
    transform: scaleY(0.25);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes scale-up-top {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0.25);
    transform: scaleY(0.25);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@-webkit-keyframes opacity-visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity-visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes opacity-invisible {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  50% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: -140px;
  }
}
@keyframes opacity-invisible {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  50% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: -140px;
  }
}

@-webkit-keyframes translate-down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes translate-down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes scale-down-center {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-down-center {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rotate-slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px) rotate(-180deg);
    transform: translateX(-50px) rotate(-180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rotate-slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px) rotate(-180deg);
    transform: translateX(-50px) rotate(-180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@-webkit-keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}
@keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  @-webkit-keyframes opacity-invisible {
    0% {
      opacity: 1;
      margin-top: 0;
    }
    50% {
      opacity: 0;
      margin-top: 0;
    }
    100% {
      opacity: 0;
      margin-top: -170px;
    }
  }
  @keyframes opacity-invisible {
    0% {
      opacity: 1;
      margin-top: 0;
    }
    50% {
      opacity: 0;
      margin-top: 0;
    }
    100% {
      opacity: 0;
      margin-top: -170px;
    }
  }
}
