.audio-caption {
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: #000000a0;
  width: calc(100% - 40px);
  max-width: 800px;
  margin: auto;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.5s ease-in;

  &.visible {
    opacity: 1;
    z-index: 9;
  }

  &__player {
    position: relative;
    width: 100%;
    margin: 0 80px;

    @media (max-height: 720px) {
      min-height: 56px;
      margin: 0 40px;
    }

    @media (max-width: 640px) {
      margin: 0 10px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    font-size: 22px;
    line-height: 1.35;
    text-align: center;
    padding: 15px 0;
    transition: all 0.5s ease-in-out;

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-height: 720px) {
      min-height: 56px;
      font-size: 20px;
      padding: 10px 0;
    }

    @media (max-width: 640px) {
      min-height: 56px;
      font-size: 18px;
      padding: 36px 0 16px;

      &.no-control {
        padding: 16px 0;
      }
    }
  }

  &__player-controls {
    display: flex;
    position: absolute;
    top: 4px;
    right: 8px;

    .control-item {
      width: 26px;
      height: 26px;
      display: flex;
      margin: 2px;
      cursor: pointer;

      &.control-volumn {
        img {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          padding: 2px;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 640px) {
        width: 24px;
        height: 24px;
        margin: 4px 2px;
      }
    }
  }

  @media (max-height: 720px) {
    max-width: 760px;
  }
}
