.page-privacy-terms {
  .box__inner {
    padding: 3rem 4.25rem !important;
  }

  .block:not(:last-child) {
    margin-bottom: 30px;
  }

  p {
    &:not(.h1, .h3) {
      font-size: 14px;
      line-height: 22px;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.h1 {
      text-align: center;
      margin-bottom: 30px;
    }

    &.h3 {
      margin-bottom: 20px;
    }
  }

  ul {
    &.no-padding {
      padding-left: 0;
    }

    &.custom-list-style {
      li {
        display: flex;
        align-items: center;
        list-style: none;

        &::before {
          content: '';
          display: flex;
          width: 14px;
          height: 2px;
          margin-right: 6px;
          background-color: #d45af8;
          border-radius: 2px;
        }
      }
    }

    &.hidden-list-style {
      li {
        list-style: none;
      }
    }

    li,
    ol {
      font-size: 14px;
      line-height: 22px;
      padding-left: 0;

      &:not(:last-child) {
        margin-bottom: 0.125rem;
      }
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .box__inner {
      padding: 1.25rem 1.125rem !important;
    }

    .block:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &.h1 {
        text-align: center;
        margin-bottom: 20px;
      }

      &.h3 {
        margin-bottom: 16px;
      }
    }
  }
}
