.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 1s ease-out;

  .numbers-mask {
    position: absolute;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1130px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:nth-child(1) {
      -webkit-animation: numbers-mask-1 5s linear both infinite;
      animation: numbers-mask-1 5s linear both infinite;

      &::before {
        background-image: url('../../../assets/images/numbers-mask-1.png');
      }
    }
    &:nth-child(2) {
      -webkit-animation: numbers-mask-2 5s linear both infinite;
      animation: numbers-mask-2 5s linear both infinite;

      &::before {
        background-image: url('../../../assets/images/numbers-mask-2.png');
      }
    }
    &:nth-child(3) {
      -webkit-animation: numbers-mask-3 5s linear both infinite;
      animation: numbers-mask-3 5s linear both infinite;

      &::before {
        background-image: url('../../../assets/images/numbers-mask-3.png');
      }
    }
  }

  .number-animation {
    position: absolute;

    @media (max-width: 768px) {
      display: none;
    }

    // left
    &:nth-child(4) {
      top: 40vh;
      left: -20px;
      font-size: 40px;
      -webkit-animation: number-move-1 8s linear both infinite;
      animation: number-move-1 8s linear both infinite;
    }
    &:nth-child(5) {
      top: 90vh;
      left: 10px;
      font-size: 30px;
      -webkit-animation: number-move-2 15s linear both infinite;
      animation: number-move-2 15s linear both infinite;
    }
    &:nth-child(6) {
      top: 90vh;
      left: 50px;
      font-size: 50px;
      -webkit-animation: number-move-3 15s linear 5s both infinite;
      animation: number-move-3 15s linear 5s both infinite;
    }
    &:nth-child(7) {
      top: 30vh;
      left: -50px;
      font-size: 40px;
      -webkit-animation: number-move-4 15s linear both infinite;
      animation: number-move-4 15s linear both infinite;
    }
    &:nth-child(8) {
      top: 30vh;
      left: 25vw;
      font-size: 30px;
      -webkit-animation: number-move-5 12s linear both infinite;
      animation: number-move-5 12s linear both infinite;
    }
    &:nth-child(9) {
      top: 70vh;
      left: 20vw;
      font-size: 50px;
      -webkit-animation: number-move-6 12s linear both infinite;
      animation: number-move-6 12s linear both infinite;
    }
    &:nth-child(10) {
      top: 55vh;
      left: 15vw;
      font-size: 20px;
      -webkit-animation: number-move-7 10s linear both infinite;
      animation: number-move-7 10s linear both infinite;
    }

    // right
    &:nth-child(11) {
      top: 50vh;
      right: -50px;
      font-size: 40px;
      -webkit-animation: right-number-move-1 8s linear both infinite;
      animation: right-number-move-1 8s linear both infinite;
    }
    &:nth-child(12) {
      top: 18vh;
      right: 50px;
      font-size: 30px;
      -webkit-animation: right-number-move-2 15s linear both infinite;
      animation: right-number-move-2 15s linear both infinite;
    }
    &:nth-child(13) {
      top: 45vh;
      right: 30vw;
      font-size: 30px;
      -webkit-animation: right-number-move-3 15s linear both infinite;
      animation: right-number-move-3 15s linear both infinite;
    }
    &:nth-child(14) {
      bottom: -50px;
      right: 10vw;
      font-size: 40px;
      -webkit-animation: right-number-move-4 15s linear both infinite;
      animation: right-number-move-4 15s linear both infinite;
    }
    &:nth-child(15) {
      bottom: -50px;
      right: 20vw;
      font-size: 30px;
      -webkit-animation: right-number-move-5 12s linear both infinite;
      animation: right-number-move-5 12s linear both infinite;
    }
    &:nth-child(16) {
      top: 55vh;
      right: 20vw;
      font-size: 50px;
      -webkit-animation: right-number-move-6 12s linear both infinite;
      animation: right-number-move-6 12s linear both infinite;
    }
    &:nth-child(17) {
      top: 45vh;
      right: 20vw;
      font-size: 40px;
      -webkit-animation: right-number-move-7 15s linear both infinite;
      animation: right-number-move-7 15s linear both infinite;
    }
  }
}

@keyframes numbers-mask-1 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes numbers-mask-2 {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes numbers-mask-3 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes number-move-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(1) translate3d(80px, 80px, 0.1px);
    transform: scale(1) translate3d(80px, 80px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(160px, 160px, 0.1px);
    transform: scale(1.5) translate3d(160px, 160px, 0.1px);
  }
}
@keyframes number-move-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(1) translate3d(80px, 80px, 0.1px);
    transform: scale(1) translate3d(80px, 80px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(160px, 160px, 0.1px);
    transform: scale(1.5) translate3d(160px, 160px, 0.1px);
  }
}

@-webkit-keyframes number-move-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  70% {
    opacity: 0.8;
    -webkit-transform: scale(1.5) translate3d(80px, -40vh, 0.1px);
    transform: scale(1.5) translate3d(80px, -40vh, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.25) translate3d(120px, -50vh, 0.1px);
    transform: scale(1.25) translate3d(120px, -50vh, 0.1px);
  }
}
@keyframes number-move-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  70% {
    opacity: 0.8;
    -webkit-transform: scale(1.5) translate3d(80px, -40vh, 0.1px);
    transform: scale(1.5) translate3d(80px, -40vh, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.25) translate3d(120px, -50vh, 0.1px);
    transform: scale(1.25) translate3d(120px, -50vh, 0.1px);
  }
}

@-webkit-keyframes number-move-3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(2) translate3d(0px, 0px, 0.1px);
    transform: scale(2) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: scale(1.7) translate3d(80px, -20vh, 0.1px);
    transform: scale(1.7) translate3d(80px, -20vh, 0.1px);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: scale(1) translate3d(60px, -50vh, 0.1px);
    transform: scale(1) translate3d(60px, -50vh, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(50px, -60vh, 0.1px);
    transform: scale(1) translate3d(50px, -60vh, 0.1px);
  }
}
@keyframes number-move-3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(2) translate3d(0px, 0px, 0.1px);
    transform: scale(2) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: scale(1.7) translate3d(80px, -20vh, 0.1px);
    transform: scale(1.7) translate3d(80px, -20vh, 0.1px);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: scale(1) translate3d(60px, -50vh, 0.1px);
    transform: scale(1) translate3d(60px, -50vh, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(50px, -60vh, 0.1px);
    transform: scale(1) translate3d(50px, -60vh, 0.1px);
  }
}

@-webkit-keyframes number-move-4 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8) translate3d(30vw, -50px, 0.1px);
    transform: scale(0.8) translate3d(30vw, -50px, 0.1px);
  }
}
@keyframes number-move-4 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8) translate3d(30vw, -50px, 0.1px);
    transform: scale(0.8) translate3d(30vw, -50px, 0.1px);
  }
}

@-webkit-keyframes number-move-5 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  25% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: scale(2) translate3d(-10vw, 50px, 0.1px);
    transform: scale(2) translate3d(-10vw, 50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8) translate3d(-25vw, 80px, 0.1px);
    transform: scale(0.8) translate3d(-25vw, 80px, 0.1px);
  }
}
@keyframes number-move-5 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  25% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: scale(2) translate3d(-10vw, 50px, 0.1px);
    transform: scale(2) translate3d(-10vw, 50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8) translate3d(-25vw, 80px, 0.1px);
    transform: scale(0.8) translate3d(-25vw, 80px, 0.1px);
  }
}

@-webkit-keyframes number-move-6 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  5% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translateX(-50px) translateY(-1000px);
    transform: scale(0.1) translateX(-50px) translateY(-1000px);
  }
}
@keyframes number-move-6 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  5% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translateX(-50px) translateY(-1000px);
    transform: scale(0.1) translateX(-50px) translateY(-1000px);
  }
}

@-webkit-keyframes number-move-7 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale(2) translate3d(-100px, -50px, 0.1px);
    transform: scale(2) translate3d(-100px, -50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(50px, 50px, 0.1px);
    transform: scale(0.5) translate3d(50px, 50px, 0.1px);
  }
}
@keyframes number-move-7 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale(2) translate3d(-100px, -50px, 0.1px);
    transform: scale(2) translate3d(-100px, -50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(50px, 50px, 0.1px);
    transform: scale(0.5) translate3d(50px, 50px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-1 {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(-350px, 200px, 0.1px);
    transform: scale(0.5) translate3d(-350px, 200px, 0.1px);
  }
}
@keyframes right-number-move-1 {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(-350px, 200px, 0.1px);
    transform: scale(0.5) translate3d(-350px, 200px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  20% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0.6;
    -webkit-transform: scale(1.5) translate3d(-100px, 500px, 0.1px);
    transform: scale(1.5) translate3d(-100px, 500px, 0.1px);
  }
}
@keyframes right-number-move-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  20% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  100% {
    opacity: 0.6;
    -webkit-transform: scale(1.5) translate3d(-100px, 500px, 0.1px);
    transform: scale(1.5) translate3d(-100px, 500px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.7;
    -webkit-transform: scale(1) translate3d(150px, 50px, 0.1px);
    transform: scale(1) translate3d(150px, 50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(200px, 300px, 0.1px);
    transform: scale(0.5) translate3d(200px, 300px, 0.1px);
  }
}
@keyframes right-number-move-3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.7;
    -webkit-transform: scale(1) translate3d(150px, 50px, 0.1px);
    transform: scale(1) translate3d(150px, 50px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(200px, 300px, 0.1px);
    transform: scale(0.5) translate3d(200px, 300px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-4 {
  0% {
    opacity: 0.3;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale(1.5) translate3d(-50px, -400px, 0.1px);
    transform: scale(1.5) translate3d(-50px, -400px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(-50px, -500px, 0.1px);
    transform: scale(1) translate3d(-50px, -500px, 0.1px);
  }
}
@keyframes right-number-move-4 {
  0% {
    opacity: 0.3;
    -webkit-transform: scale(1) translate3d(0px, 0px, 0.1px);
    transform: scale(1) translate3d(0px, 0px, 0.1px);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale(1.5) translate3d(-50px, -400px, 0.1px);
    transform: scale(1.5) translate3d(-50px, -400px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(-50px, -500px, 0.1px);
    transform: scale(1) translate3d(-50px, -500px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-5 {
  0% {
    bottom: -50px;
    right: 20vw;
    opacity: 0.8;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    bottom: 70vh;
    right: -50px;
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes right-number-move-5 {
  0% {
    bottom: -50px;
    right: 20vw;
    opacity: 0.8;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    bottom: 70vh;
    right: -50px;
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes right-number-move-6 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  80% {
    opacity: 0.7;
    -webkit-transform: scale(1) translate3d(400px, 100px, 0.1px);
    transform: scale(1) translate3d(400px, 100px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(300px, 200px, 0.1px);
    transform: scale(0.5) translate3d(300px, 200px, 0.1px);
  }
}
@keyframes right-number-move-6 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5) translate3d(0px, 0px, 0.1px);
    transform: scale(1.5) translate3d(0px, 0px, 0.1px);
  }
  80% {
    opacity: 0.7;
    -webkit-transform: scale(1) translate3d(400px, 100px, 0.1px);
    transform: scale(1) translate3d(400px, 100px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(300px, 200px, 0.1px);
    transform: scale(0.5) translate3d(300px, 200px, 0.1px);
  }
}

@-webkit-keyframes right-number-move-7 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: scale(1) translate3d(-50px, 200px, 0.1px);
    transform: scale(1) translate3d(-50px, 200px, 0.1px);
  }
  80% {
    opacity: 0.7;
    -webkit-transform: scale(1.5) translate3d(-100px, 300px, 0.1px);
    transform: scale(1.5) translate3d(-100px, 300px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(-150px, 400px, 0.1px);
    transform: scale(0.5) translate3d(-150px, 400px, 0.1px);
  }
}
@keyframes right-number-move-7 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(0px, 0px, 0.1px);
    transform: scale(0.5) translate3d(0px, 0px, 0.1px);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: scale(1) translate3d(-50px, 200px, 0.1px);
    transform: scale(1) translate3d(-50px, 200px, 0.1px);
  }
  80% {
    opacity: 0.7;
    -webkit-transform: scale(1.5) translate3d(-100px, 300px, 0.1px);
    transform: scale(1.5) translate3d(-100px, 300px, 0.1px);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate3d(-150px, 400px, 0.1px);
    transform: scale(0.5) translate3d(-150px, 400px, 0.1px);
  }
}
