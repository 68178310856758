.page-signin {
  width: 100%;
  margin-top: 3rem;

  .box__wrapper {
    display: flex;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.68;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 20px;
    line-height: 1.22;
  }

  .description-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(55% - 160px);
    padding: 50px 80px;
    margin-right: 1rem;

    .text-body {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      margin-top: 1.25rem;
    }

    .not-a-member {
      display: flex;
      font-size: 20px;
      margin-top: 1.5rem;

      .sign-up-here {
        color: #db3dff;
        font-weight: 700;
        margin-left: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .box__inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 50px 80px 60px;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;

    p {
      margin-bottom: 1rem;

      &.error {
        color: #ff9a9a;
      }
      &.success {
        color: #db3dff;
      }
    }
  }

  @media (max-width: 1440px) {
    .box__inner {
      padding: 50px 50px 60px;
    }
  }

  @media (max-width: 1280px) {
    .description-area {
      width: calc(55% - 100px);
      padding: 50px;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 0;

    .box__wrapper {
      flex-direction: column;
      max-width: 600px;
      margin: auto;
    }

    .description-area {
      width: auto;
      padding: 50px;
      margin-right: 0;
    }
  }

  @media (max-width: 640px) {
    .description-area {
      padding: 1.5rem 1.5rem 2rem;

      .text-body {
        font-size: 14px;
      }

      .not-a-member {
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        margin-top: 1rem;

        .sign-up-here {
          margin-top: 0.5rem;
          margin-left: 0;
        }
      }
    }

    .box__inner {
      padding: 1.5rem 1.5rem 2rem;
    }

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 18px;
    }

    .btn-login {
      font-size: 24px;
      padding: 0.75rem;
      border-radius: 8px;
    }
  }

  @media (max-width: 480px) {
    .description-area {
      padding: 1rem 0.5rem 2rem;
    }

    .box__inner {
      padding: 1rem 1rem 1.5rem;
    }
  }
}
