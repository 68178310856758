.page-thank-you {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  height: calc(100vh - 124px);
  background-image: url('../../../assets//images/thankyou-1.png');
  margin: auto auto -28px;

  p {
    text-align: center;
  }

  .box__wrapper {
    position: relative;
    width: 100%;
    margin: 0 50px;

    &::before {
      content: '';
      position: absolute;
      left: -100px;
      bottom: -50px;
      width: 200px;
      height: 315px;
      background-image: url('../../../assets//images/thankyou-2.png');
      background-size: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: -50px;
      right: -150px;
      width: 250px;
      height: 383px;
      background-image: url('../../../assets//images/thankyou-3.png');
      background-size: cover;
    }
  }

  .box__inner {
    padding: 30px 50px 35px;

    .h2 {
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p.body-text {
      max-width: 780px;
      font-size: 20px;
      line-height: 1.4;
      margin: 0 auto 1rem;
    }

    p.note-text {
      color: #c8a2e3;
      font-size: 14px;
    }
  }

  .box__bottom {
    margin: 35px 35px 25px;

    p {
      max-width: 800px;
      color: #fff;
      font-size: 14px;
      line-height: 1.55;
      margin: auto;
    }
  }

  .login-link {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    a {
      color: #ac2dd2;
      font-weight: 600;
    }

    p.body-text {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 144px);

    .box__wrapper {
      &::before {
        left: -120px;
      }

      &::after {
        right: -180px;
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    margin: auto auto -2rem;
    padding: 100px 0;

    .box__wrapper {
      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }

    .box__inner {
      padding: 30px;
    }
  }

  @media (max-width: 640px) {
    .box__wrapper {
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 50px 0;
    margin: auto;

    .box__inner {
      padding: 1.5rem;

      p.body-text {
        font-size: 16px;
      }
    }

    .box__bottom {
      margin: 25px 15px 15px;
    }
  }
}
