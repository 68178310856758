.quiz-full-name {
  position: relative;
  display: flex;
  justify-content: center;
}

.life-path-end {
  position: absolute;
  margin: 2rem 0;
  opacity: 0;
  transition: all 1s ease-in;

  &.display {
    opacity: 1;
  }

  .image-container {
    width: calc(100% - 4rem);
    max-width: 420px;
    margin: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    @media (min-width: 481px) and (max-height: 720px) {
      max-width: 340px;
    }
  }
}

.quiz-full-name-form {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0.25);
  transform-origin: center;
  transition: all 0.8s ease-in;

  &.entered {
    opacity: 1;
    transform: scale(1);
    z-index: 99;
  }

  .h2 {
    max-width: 780px;
    text-align: center;
    margin: 5rem auto 1.5rem;

    @media (max-width: 1024px) {
      margin: 2rem auto 1.5rem;
    }

    @media (max-width: 640px) {
      margin: 2rem auto 1.5rem;
    }

    @media (min-width: 481px) and (max-height: 720px) {
      margin: 2rem auto 1.5rem;
    }
  }

  .form-content {
    display: flex;
    flex-wrap: wrap;

    .input-item {
      display: flex;
      flex-direction: column;
      width: calc(32% - 1.25rem);
      margin: 0 1.25rem 1rem 0;

      label {
        min-height: 48px;
        font-size: 20px;
        margin-bottom: 0.5rem;
        padding-left: 1rem;
        white-space: nowrap;

        span {
          color: #ffffffa0;
          font-size: 80%;
        }
      }

      &.select-item {
        flex: 1;
        margin-right: 0;
      }
    }

    input,
    select {
      margin-bottom: 0;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-indent: 1px;

      &.placeholder {
        color: #bca2cf;
      }
    }

    .error-text {
      color: #ff9a9a;
      font-size: 14px;
      padding: 0.5rem 0 0 1rem;
    }
  }

  .btn-continue {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    font-size: 30px;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    margin: 0.5rem auto 0;

    &:hover {
      box-shadow: 0px 5px 20px rgba(152, 58, 219, 0.55);
    }
  }

  @media (max-width: 1280px) {
    .form-content {
      input {
        min-width: 100px;
      }
    }

    .btn-continue {
      font-size: 26px;
    }
  }

  @media (max-width: 1024px) {
    .form-content {
      .input-item {
        width: calc(50% - 0.625rem);

        &:nth-child(2) {
          margin-right: 0;
        }
      }

      .select-item {
        width: 100%;

        label {
          min-height: unset;
        }
      }
    }

    .btn-continue {
      width: calc(100% - 4rem);
      height: auto;
      padding: 1rem 2rem;
    }
  }

  @media (max-width: 768px) {
    .h2 {
      font-size: 24px;
    }
  }

  @media (max-width: 640px) {
    .h2 {
      margin: 1.5rem auto;
    }

    .form-content {
      .input-item {
        width: 100%;
        margin: 0 0 0.5rem;

        label {
          font-size: 16px;
          min-height: 40px;
          white-space: pre-wrap;
        }

        label.empty {
          min-height: unset;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .h2 {
      font-size: 20px;
    }

    .btn-continue {
      font-size: 22px;
    }
  }

  @media (min-width: 481px) and (max-height: 720px) {
    .h2 {
      margin-top: 0.5rem;
    }
  }
}
