body {
  .background-images {
    opacity: 0;
    transition: all 1s ease-out;
  }
}

body.switch-background {
  .App {
    &::before {
      opacity: 0;
    }
  }

  .background-animation {
    opacity: 0;
  }

  .background-images {
    opacity: 1;
  }
}

.audio-caption-wrapper {
  position: fixed;
  left: 0;
  bottom: calc(15vh + 90px);
  width: 100vw;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    bottom: 150px;
  }

  @media (max-width: 768px) {
    bottom: 200px;
  }

  @media (max-width: 480px) {
    bottom: 120px;
  }

  @media (min-width: 769px) and (max-height: 720px) {
    bottom: 150px;
  }
}

@media (max-width: 480px) {
  .destiny-number-report + .audio-caption-wrapper {
    top: 420px;
  }

  .life-path-number-calculation + .audio-caption-wrapper,
  .content-order-form + .audio-caption-wrapper {
    top: unset;
    bottom: 120px !important;
  }
}
