@font-face {
  font-family: 'Roboto Condensed';
  src: url('../../../assets/fonts/RobotoCondensed/RobotoCondensed-VariableFont_wght.ttf');
}

.new-message-1 {
  &::after {
    content: '';
    position: fixed;
    left: 0;
    bottom: 0;
    width: 64vw;
    height: 50vw;
    background-image: url('../../../assets/images/upsell/page-bottom-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }

  footer {
    width: 100%;
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }

  & + .clickbank-note {
    max-width: 885px !important;

    p {
      padding: 0 !important;
    }

    @media (max-width: 960px) {
      max-width: unset !important;
    }
  }
}

.page-upsell {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed';
  width: 100%;
  max-width: 890px;
  margin: 35px auto 50px;
  z-index: 1;

  .heading-h1 {
    font-size: 54px;
    font-weight: 700;
    line-height: 1.14;
    background: linear-gradient(90deg, #c19902 1.98%, #e3c655 95.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .heading-h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.18;
  }

  .heading-h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.14;
  }

  .text-body {
    font-size: 22px;
    line-height: 27px;
  }

  .medium-italic {
    font-style: italic;
    font-weight: 500;
  }

  .color-pink {
    color: #c71ff0;
  }

  .color-white {
    color: #fff;
  }

  .fw-bold {
    font-weight: 700;
  }

  .hero {
    display: flex;
    flex-direction: column;
    background-color: #1a1649;
    padding-top: 4px;
    border-radius: 12px;

    &__upper {
      position: relative;
      background-image: url('../../../assets/images/upsell/box-bg-hero.png');
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 20px;
      border-radius: 12px 12px 0 0;

      .logos-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
          svg {
            width: 225px;
            height: auto;
          }
        }

        img {
          margin-left: 24px;
        }
      }

      .steps-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .step-one {
          background: #fff;
          color: #1a1649;
          font-size: 22px;
          font-weight: 700;
          line-height: 42px;
          padding: 10px 28px;
        }

        .step-two {
          background-image: url('../../../assets/images/upsell/arrow-bg.png');
          background-repeat: no-repeat;
          background-size: contain;
          color: #00f;
          font-size: 26px;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
          padding: 18px 72px 12px 54px;

          span {
            font-size: 32px;
          }
        }
      }
    }

    &__lower {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #3d1957;
      padding: 24px;
      border-radius: 0 0 12px 12px;

      p {
        text-align: center;
      }

      p:last-child {
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.46;
        margin-top: 10px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    background-color: #1a1649;
    border-radius: 12px;
    padding: 24px;
    margin-top: 18px;

    h1,
    h2 {
      text-align: center;
    }

    .header {
      position: relative;
      z-index: 1;

      h1 {
        margin: 28px 0 16px;
      }

      h2 {
        padding: 0 24px;
      }
    }

    .blueprint-with-text {
      position: relative;
      margin-top: 40px;

      &::before {
        content: '';
        position: absolute;
        top: -140px;
        left: -40px;
        width: 447px;
        height: 721px;
        background-image: url('../../../assets/images/upsell/container-bg-1.png');
        background-repeat: no-repeat;
        background-size: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: -215px;
        right: -24px;
        width: 284px;
        height: 1069px;
        background-image: url('../../../assets/images/upsell/container-bg-2.png');
        background-repeat: no-repeat;
        background-size: cover;
      }

      .image-wrapper {
        position: absolute;
        top: -48px;
        left: -35px;
        z-index: 1;

        img {
          width: 402px;
          height: auto;
        }
      }

      .text-body {
        position: relative;
        width: 543px;
        color: #a2a0db;
        margin-left: auto;
        z-index: 1;
      }
    }

    .power-of-manifest {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      z-index: 1;

      h1 {
        text-align: center;
      }

      .image-and-text {
        display: flex;
        margin: 10px 0 20px;

        img {
          width: 157px;
          height: 166px;
          border: 12px solid #342f68;
          border-radius: 55px;
          margin-right: 32px;
        }

        .text-body {
          margin-top: 8px;
        }
      }

      .text-body {
        color: #a2a0db;
      }
    }

    .law-of-attraction {
      margin-top: 50px;

      h2.heading-h2 {
        max-width: 800px;
        font-style: italic;
        font-weight: 500;
        margin: 30px auto 0;
      }

      p.heading-h2 {
        max-width: 600px;
        color: #df60ff;
        text-align: center;
        margin: 40px auto 0;
      }

      .quote-box {
        display: flex;
        position: relative;
        background-color: #282364;
        border-radius: 8px;
        padding: 48px 32px 40px 50px;
        margin-top: 36px;

        &::before {
          content: '';
          position: absolute;
          top: -18px;
          left: 40px;
          width: 32px;
          height: 28px;
          background-image: url('../../../assets/images/upsell/quote-prefix.png');
          background-repeat: no-repeat;
        }

        &::after {
          content: '';
          position: absolute;
          right: 50px;
          bottom: -16px;
          width: 32px;
          height: 30px;
          background-image: url('../../../assets/images/upsell/quote-suffix.png');
          background-repeat: no-repeat;
        }

        i {
          color: #e2c553;
        }

        .left-col {
          display: flex;
          flex-direction: column;
          max-width: 380px;

          .text-body + .text-body {
            margin-top: 20px;
          }
        }

        .right-col {
          flex: 1;
          margin-left: 25px;
        }
      }

      .bg-image-box {
        background-color: #3d1957;
        background-image: url('../../../assets/images/upsell/box-bg-1.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        padding: 45px 50px;
        margin-top: 30px;
      }
    }

    .my-ultimate {
      margin-top: 50px;

      .arrow-down {
        display: flex;
        justify-content: center;
        margin: 20px auto;
      }

      .arrow-down + .letter-box {
        margin-top: 0;
      }

      .letter-box {
        position: relative;
        background-image: url('../../../assets/images/upsell/box-bg-2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 60px 40px 30px;
        margin-top: 32px;
        border: 5px dashed #dc49ff;
        border-radius: 8px;

        &::before {
          content: '';
          position: absolute;
          top: 75px;
          left: -25px;
          width: 45px;
          height: 55px;
          background-image: url('../../../assets/images/upsell/scissors.png');
          background-repeat: no-repeat;
          background-size: contain;
        }

        .box-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: auto;
          width: fit-content;

          .discount {
            display: flex;
            font-weight: 700;

            .was-price {
              p:first-child {
                font-size: 34px;
                line-height: 32px;
                text-transform: uppercase;
              }

              p:last-child {
                font-size: 42px;
                line-height: 56px;
              }
            }

            .today-price {
              font-size: 88px;
              line-height: 88px;
              margin-left: 12px;
            }
          }

          .one-time-fee {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.33;
            text-transform: uppercase;
            margin: 30px auto 5px;
          }

          .cards-image {
            width: 248px;
            height: auto;
          }

          a {
            text-decoration: none;
          }

          .upgrade-btn {
            background: #fffd00;
            color: #00f;
            font-size: 36px;
            font-weight: 700;
            line-height: 1.72;
            text-align: center;
            padding: 10px 25px;
            border: 5px solid #00f;
            border-radius: 12px;
            margin: 20px auto;
          }
        }

        .image-wrapper {
          position: absolute;
          top: 15px;
          right: -15px;

          img {
            max-width: 326px;
          }
        }

        .no-thanks {
          position: relative;
          text-align: center;
          z-index: 1;

          a {
            color: #adadad;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.58;
          }
        }
      }
    }

    .stop-scrolling-box {
      background-image: url('../../../assets/images/upsell/box-bg-3.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 35px 60px 35px 50px;
      margin-top: 30px;
      border-radius: 8px;
    }

    .ultimate-manifestation-guide {
      margin-top: 40px;

      .heading-h2 {
        margin-bottom: 28px;
      }

      .card-name {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.6;
        margin: 2px 0;
      }

      .card-text {
        color: #a2a0db;
        font-size: 20px;
        line-height: 1.25;
      }

      .cards-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        .card {
          background: #252157;
          border-radius: 8px;
          padding: 20px 25px 15px;
        }

        &.testimonial {
          margin-top: 50px;

          .card {
            position: relative;
            padding: 30px 25px 25px;

            &::before {
              content: '';
              position: absolute;
              top: -18px;
              left: 25px;
              width: 32px;
              height: 28px;
              background-image: url('../../../assets/images/upsell/quote-prefix-purple.png');
              background-repeat: no-repeat;
              background-size: contain;
            }

            &::after {
              content: '';
              position: absolute;
              right: 20px;
              bottom: -16px;
              width: 32px;
              height: 30px;
              background-image: url('../../../assets/images/upsell/quote-suffix-purple.png');
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    .container {
      .blueprint-with-text {
        &::before {
          left: -30px;
          width: 400px;
          height: 640px;
        }

        &::after {
          top: -190px;
          width: 250px;
          height: 920px;
        }

        .image-wrapper {
          top: -40px;

          img {
            width: 340px;
          }
        }

        .text-body {
          width: calc(100% - 260px);
        }
      }

      .law-of-attraction {
        .quote-box {
          .left-col {
            flex: 1;
            max-width: unset;
          }
        }
      }

      .my-ultimate {
        .letter-box {
          padding: 40px 30px 30px;

          .box-content {
            width: 63%;

            .upgrade-btn {
              font-size: 30px;
              padding: 5px 15px;
            }
          }

          .image-wrapper {
            top: 10px;
            left: 62%;
            right: unset;

            img {
              width: 285px;
            }
          }

          .no-thanks {
            a {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .heading-h1 {
      font-size: 42px;
    }

    .heading-h2 {
      font-size: 28px;
    }

    .heading-h3 {
      font-size: 24px;
    }

    .text-body {
      font-size: 18px;
      line-height: 22px;
    }

    .hero {
      &__upper {
        .steps-wrapper {
          .step-one {
            font-size: 18px;
            line-height: 32px;
          }

          .step-two {
            font-size: 22px;
            line-height: 26px;
            padding: 18px 60px 12px 54px;

            span {
              font-size: 28px;
            }
          }
        }
      }

      &__lower {
        p:last-child {
          font-size: 22px;
        }
      }
    }

    .container {
      .blueprint-with-text {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        &::before {
          top: -80px;
          width: 100vw;
          max-width: 400px;
          height: 120vw;
          background-size: contain;
          z-index: 1;
        }

        &::after {
          top: 350px;
          width: 50vw;
          max-width: 200px;
          height: 200vw;
          background-size: contain;
        }

        .image-wrapper {
          display: flex;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;

          img {
            width: 100%;
            max-width: 400px;
            margin: auto;
          }
        }

        .text-body {
          width: 100%;
          margin-left: 0;
        }
      }

      .power-of-manifest {
        .image-and-text {
          flex-direction: column;

          img {
            margin: 16px 0;
          }
        }
      }

      .law-of-attraction {
        margin-top: 30px;

        p.heading-h2 {
          margin: 30px auto 0;
        }

        .quote-box {
          flex-direction: column;
          padding: 40px 30px;

          .right-col {
            margin-left: 0;
          }
        }

        .bg-image-box {
          padding: 30px;
        }
      }

      .my-ultimate {
        .letter-box {
          &::before {
            top: 50px;
            left: -20px;
            width: 35px;
            height: 45px;
          }

          .box-content {
            width: fit-content;
            margin: auto;

            .discount {
              .was-price {
                p:first-child {
                  font-size: 30px;
                  line-height: 26px;
                }

                p:last-child {
                  font-size: 36px;
                  line-height: 40px;
                }
              }

              .today-price {
                font-size: 66px;
                line-height: 66px;
              }
            }

            .one-time-fee {
              font-size: 20px;
              margin: 20px auto 10px;
            }

            .upgrade-btn {
              font-size: 26px;
            }
          }

          .image-wrapper {
            position: relative;
            top: unset;
            left: unset;
            text-align: center;

            img {
              width: 100%;
              max-width: 300px;
            }
          }

          .no-thanks {
            a {
              font-size: 20px;
            }
          }
        }
      }

      .stop-scrolling-box {
        padding: 30px;
      }

      .ultimate-manifestation-guide {
        margin-top: 30px;

        .cards-list {
          grid-template-columns: 1fr;

          &.testimonial {
            margin-top: 30px;
            gap: 30px;

            .card::before {
              top: -10px;
              width: 28px;
              height: 24px;
            }

            .card::after {
              right: 25px;
              bottom: -10px;
              width: 28px;
              height: 24px;
            }
          }

          .card {
            padding: 20px 25px !important;

            img {
              width: 24px;
            }
          }
        }

        .card-name {
          font-size: 18px;
        }

        .card-text {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .heading-h1 {
      font-size: 32px;
    }

    .heading-h2 {
      font-size: 22px;
      line-height: 1.24;
    }

    .heading-h3 {
      font-size: 22px;
    }
    .text-body {
      font-size: 16px;
    }

    .hero {
      &__upper {
        .logos-wrapper {
          flex-direction: column;
          padding: 15px 0 25px;

          .logo {
            margin-bottom: 0;

            svg {
              width: 200px;
            }
          }

          img {
            width: 200px;
            margin-left: 0;
          }
        }

        .steps-wrapper {
          flex-direction: column;

          .step-one {
            padding: 5px 25px;
          }

          .step-two {
            font-size: 18px;
            line-height: 22px;
            padding: 12px 50px 12px 45px;
          }
        }
      }

      &__lower {
        p:last-child {
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }

    .container {
      padding: 24px 16px;

      .header {
        .heading-h1 {
          margin: 0 0 12px;
        }

        .heading-h2 {
          padding: 0;
        }
      }

      .power-of-manifest {
        .image-and-text {
          img {
            width: 145px;
            height: auto;
            border-width: 8px;
            border-radius: 24px;
          }
        }
      }

      .law-of-attraction {
        .quote-box {
          padding: 24px 20px;

          &::before {
            top: -12px;
            left: 24px;
            width: 28px;
            height: 24px;
            background-size: contain;
          }

          &::after {
            bottom: -12px;
            right: 24px;
            width: 28px;
            height: 24px;
            background-size: contain;
          }
        }

        .bg-image-box {
          padding: 20px;
        }
      }

      .my-ultimate {
        margin-top: 40px;

        .letter-box {
          padding: 24px 20px;
          margin-top: 20px;
          border-width: 4px;

          .box-content {
            .discount {
              .was-price {
                p:first-child {
                  font-size: 24px;
                  line-height: 22px;
                }

                p:last-child {
                  font-size: 28px;
                  line-height: 34px;
                }
              }

              .today-price {
                font-size: 56px;
                line-height: 56px;
              }
            }

            .one-time-fee {
              font-size: 18px;
            }

            .cards-image {
              width: 200px;
            }

            .upgrade-btn {
              font-size: 20px;
              border-width: 3px;
              border-radius: 8px;
            }
          }

          .no-thanks {
            a {
              font-size: 18px;
              line-height: 1.4;
            }
          }
        }
      }

      .stop-scrolling-box {
        padding: 20px;
      }

      .ultimate-manifestation-guide {
        .cards-list {
          .card {
            padding: 20px !important;
          }
        }

        .card-text {
          font-size: 16px;
        }
      }
    }
  }
}
