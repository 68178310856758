.affiliates {
  position: relative;
  max-width: unset !important;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 320px;
    background: url('../../../assets/images/affiliates/gold-and-star.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9;

    @media (max-width: 1024px) {
      height: 300px;
      background-size: contain;
    }
  }

  footer {
    width: 100%;
    max-width: 1368px;
    margin: 2rem auto 1.5rem;

    @media (max-width: 1440px) {
      max-width: 1168px;
    }

    @media (max-width: 1200px) {
      width: calc(100% - 2rem);
      margin: 2rem 1rem 1.5rem;
    }
  }
}

.page-affiliates {
  width: 100%;
  max-width: 1400px;
  margin: auto;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    background-color: #1a1649;
    border-radius: 12px;
    padding: 1rem;
    margin: 10rem auto;

    &::before {
      content: '';
      position: absolute;
      top: -90px;
      right: 20px;
      width: 60%;
      height: 100%;
      max-height: 800px;
      background: url('../../../assets/images/affiliates/light-and-gold.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.14;
    text-align: center;
    margin: 0 0 20px 0;
  }

  p {
    line-height: 1.55;
  }

  .flex-row {
    display: flex;
  }

  .text-block {
    display: flex;
    flex-direction: column;
  }

  .mb0 {
    margin-bottom: 0 !important;
  }
  .mb10 {
    margin-bottom: 10px !important;
  }

  .hero {
    display: flex;
    flex-direction: column;
    padding: 30px 0 150px 60px;

    svg {
      margin: auto;
    }

    h2 {
      font-size: 54px;
      font-weight: 700;
      text-transform: uppercase;
      background: linear-gradient(90deg, #c19902 1.98%, #e3c655 95.27%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 80px;
    }

    h3 {
      max-width: 350px;
      font-size: 32px;
      line-height: 1.3;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 0;
    }

    p {
      max-width: 390px;
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .welcome {
    position: relative;
    background: #3d1957;
    padding: 80px 100px 0;
    border-radius: 8px;

    .text-block {
      max-width: 485px;
      margin-left: 30px;
      margin-top: 60px;

      p {
        font-size: 14px;
        margin-bottom: 1.5rem;
      }

      p:last-child {
        font-size: 22px;
      }
    }

    .image-block {
      flex: 1;
      overflow-y: hidden;

      img {
        width: 100%;
        margin-top: -70px;
      }
    }
  }

  .partner {
    padding: 100px 0;

    .ultimate-choice {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      row-gap: 20px;
      margin-top: 24px;

      .ultimate-choice__item {
        position: relative;
        background: #252157;
        border-radius: 8px;
        padding: 30px 24px 30px 64px;

        &::before {
          content: '';
          position: absolute;
          width: 26px;
          height: 26px;
          background: url('../../../assets/images/affiliates/check-mark.svg');
          margin-left: -40px;
          margin-top: 3px;
        }

        .item-title {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.8;
        }

        .item-subtitle {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          line-height: 2.28;
        }

        .item-body {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.57;
          margin-bottom: 20px;
        }
      }

      .join-us {
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: var(
          --1,
          linear-gradient(91deg, #4f0098 0.44%, #bd35dc 99.57%)
        );
        padding: 30px 50px;

        &::before {
          display: none;
        }

        p {
          color: #fff;
          font-size: 28px;
          font-weight: 600;
          line-height: 1.35;
          margin-left: 48px;
        }
      }
    }
  }

  .affiliate-links {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/images/affiliates/affiliate-links.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 5.5rem 6rem 4.5rem;

    .text-block {
      max-width: 420px;
      margin: 0 130px 0 50px;

      h3 {
        text-align: left;
        margin-bottom: 1rem;
      }

      p {
        font-size: 14px;
        margin-bottom: 1.25rem;
      }
    }

    .generate-form {
      background: #ffffff1f;
      border-radius: 8px;
      padding: 14px;

      &__inner {
        display: flex;
        flex-direction: column;
        width: fit-content;
        background: #1a1649;
        border-radius: 8px;
        padding: 2.75rem 2rem 3rem;

        h3 {
          margin-bottom: 0;
        }

        label {
          color: #7a75b1;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          margin-left: 10px;
        }

        .input-item {
          display: flex;
          flex-direction: column;
          margin-top: 1.25rem;

          label {
            margin-bottom: 0.25rem;
          }

          input {
            width: 328px;
            line-height: 1.46;
            background: #252157;
            border-radius: 8px;
            margin-bottom: 0;
          }
        }

        .checkbox-item {
          display: flex;
          align-items: center;
          margin: 1rem 0;

          label {
            line-height: 1.2;
          }

          .checkbox-input {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background: #f2ecec;
            border: 1px solid #f2ecec;
            border-radius: 8px;
            cursor: pointer;

            svg {
              display: none;
            }

            &.checked {
              svg {
                display: block;
              }
            }

            &.error {
              background-color: #ff000020;
              border-color: #ff0000;
            }
          }
        }

        .generate-hoplink {
          max-width: 352px;
          background: linear-gradient(270deg, #8538cc 0.99%, #e160ff 99.2%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 18px;
          font-weight: 700;
          word-wrap: break-word;
          margin: 1rem 1rem 2rem;
        }

        .btn-generate {
          font-size: 28px;
          font-weight: 400;
          padding: 1.875rem 1.25rem;
        }
      }
    }
  }

  .install-pixels {
    position: relative;
    display: flex;
    align-items: center;
    margin: 3rem -1rem;

    .image-block {
      width: 55%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .text-block {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      margin: 0 3rem;

      p {
        font-size: 14px;
        margin-bottom: 20px;
      }

      a {
        color: #b732d8;
      }
    }
  }

  .email-swipes {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #3d1957;
    border-radius: 8px;
    padding: 1.25rem;
    margin-bottom: 50px;
    overflow: hidden;

    &__header {
      display: flex;
      height: 300px;

      .text-block {
        width: 50%;
        max-width: 590px;
        display: flex;
        flex-direction: column;
        padding: 4rem 5rem;

        h3 {
          text-align: left;
        }

        p {
          font-size: 14px;
        }
      }

      .image-block {
        margin: 3rem 0 0 4rem;
      }
    }
  }

  .product-images {
    position: relative;
    margin: 50px 0;

    &__header {
      display: flex;
      flex-direction: column;
      max-width: 434px;
      margin: 0 auto 30px;

      p {
        font-size: 14px;
        line-height: 1.57;
        margin-bottom: 20px;
      }
    }

    &__content {
      .images-columns-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .images-columns-4 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;
      }

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }
  }

  .cover-pdf {
    position: relative;
    background-image: url('../../../assets/images/affiliates/cover-pdf.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 50px 0;
    padding: 1.25rem;
    border-radius: 8px;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(15, 15, 46, 0);
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 40px 100px;

      .logo {
        width: 565px;
        height: auto;
      }

      .urge-number {
        width: 420px;
        height: auto;
      }
    }
  }

  .affiliate-terms {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 50px 0;

    p {
      font-size: 14px;
      line-height: 1.57;
      margin-bottom: 20px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      max-width: 445px;
      margin: 0 auto 50px;
    }

    &__content-desktop {
      display: flex;
      gap: 40px;

      .left-content,
      .right-content {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }

    &__content-mobile {
      display: none;
      flex-direction: column;
    }

    .terms-item {
      display: flex;
      margin-bottom: 20px;
      padding-right: 1rem;
    }

    .number-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 51px;
      min-width: 51px;
      height: 51px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      background: linear-gradient(0deg, #4f0098 1%, #bd35dc 94.91%);
      border-radius: 18px;
      margin-right: 20px;
      padding: 0;
    }
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: url('../../../assets/images/affiliates/contact-us.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 50px;
    padding: 4.5rem 1rem 4rem;
    border-radius: 8px;

    h3 {
      margin-bottom: 30px;
    }

    p {
      max-width: 666px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    a {
      color: #c630f0;
    }
  }

  @media (max-width: 1440px) {
    max-width: 1200px;

    h3 {
      font-size: 26px;
    }

    .hero {
      svg {
        width: 240px;
        height: auto;
      }

      h2 {
        font-size: 48px;
      }

      h3 {
        max-width: 300px;
        font-size: 28px;
      }

      p {
        max-width: 350px;
        font-size: 16px;
      }
    }

    .welcome {
      padding: 60px 80px 0;

      .text-block {
        width: 45%;
        margin-left: 0;
      }

      .image-block {
        img {
          margin-top: -50px;
        }
      }
    }

    .email-swipes {
      &__header {
        height: 260px;

        .text-block {
          max-width: 500px;
          padding: 3rem 4rem 2rem;
        }

        .image-block {
          margin: 2.5rem 0 0 3rem;

          svg {
            width: 300px;
            height: auto;
          }
        }
      }
    }

    .cover-pdf__inner {
      padding: 30px 80px;

      .logo {
        width: 500px;
        height: auto;
      }

      .urge-number {
        width: 380px;
        height: auto;
      }
    }
  }

  @media (max-width: 1200px) {
    .container {
      margin: 8rem auto;

      &::before {
        top: -10px;
      }
    }

    .welcome {
      padding: 60px 60px 0;

      .text-block {
        margin-top: 20px;
      }

      .image-block {
        display: flex;
        align-items: center;
      }
    }

    .partner .ultimate-choice .join-us {
      img {
        width: 90px;
      }
      p {
        font-size: 24px;
        margin-left: 36px;
      }
    }

    .affiliate-links {
      padding: 4rem;

      .text-block {
        max-width: 420px;
        margin: 0 50px 0 0;
      }

      .generate-form__inner {
        padding: 2.75rem 2rem 3rem;

        .input-item {
          input {
            width: 260px;
          }
        }

        .generate-hoplink {
          width: 292px;
        }

        .btn-generate {
          padding: 1.25rem;
        }
      }
    }

    .email-swipes {
      &__header {
        .text-block {
          width: calc(50% - 3rem);
          padding: 3rem 3rem 2rem;
        }

        .image-block {
          flex: 1;
          margin: 3rem 0 0 3rem;

          svg {
            width: 70%;
          }
        }
      }
    }

    .product-images__content {
      img {
        height: 360px;
      }
    }

    .cover-pdf__inner {
      padding: 30px 50px;

      .logo {
        width: 55%;
        height: auto;
      }

      .urge-number {
        width: 40%;
        height: auto;
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      margin: 6rem auto;

      &::before {
        top: 0;
        height: 560px;
        background-position: bottom;
      }
    }

    .welcome {
      padding: 60px 60px 0;

      .text-block {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .image-block {
        display: flex;
        align-items: center;
      }
    }

    .affiliate-links {
      flex-direction: column;

      .text-block {
        max-width: unset;
        margin: 0 0 2rem 0;
      }
    }

    .install-pixels {
      .text-block {
        margin: 0 1.5rem;
      }
    }

    .email-swipes {
      &__header {
        height: auto;

        .text-block {
          width: calc(50% - 2rem);
          padding: 2rem 2rem 1rem;
        }

        .image-block {
          margin: 2rem 0 2rem 3rem;

          svg {
            width: 70%;
            max-width: 240px;
          }
        }
      }
    }

    .product-images__content {
      img {
        height: 300px;
      }
    }

    .affiliate-terms {
      .number-box {
        width: 44px;
        min-width: 44px;
        height: 44px;
        font-size: 20px;
        line-height: 24px;
        border-radius: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      margin: 4rem auto;

      &::before {
        top: 40px;
        height: 560px;
        background-position: bottom;
      }
    }

    .hero {
      padding: 30px 0 200px 30px;

      h2 {
        font-size: 36px;
        margin-top: 40px;
      }

      h3 {
        font-size: 24px;
      }
    }

    .welcome {
      padding: 40px 40px 0;

      .flex-row {
        flex-direction: column;
      }

      .text-block {
        width: 100%;
        max-width: unset;
        margin-bottom: -10px;
      }

      .image-block {
        display: flex;
        justify-content: center;

        img {
          width: 80%;
          margin-top: 0;
        }
      }
    }

    .partner .ultimate-choice {
      grid-template-columns: 1fr;
    }

    .affiliate-links {
      padding: 2rem;

      .text-block {
        margin: 0 0 1rem 0;
      }

      .generate-form__inner {
        max-width: 360px;
        padding: 2rem 1.5rem;

        .input-item {
          input {
            width: calc(100% - 56px);
          }
        }

        .generate-hoplink {
          width: calc(100% - 16px);
          margin: 0.5rem 0.5rem 1.5rem;
        }
      }
    }

    .install-pixels {
      flex-direction: column;
      align-items: normal;

      .image-block {
        width: 80%;
        margin-bottom: 2rem;
      }

      .text-block {
        max-width: unset;
        margin: 0 1.5rem;
      }
    }

    .email-swipes {
      &__header {
        flex-direction: column-reverse;

        .text-block {
          width: calc(100% - 4rem);
          max-width: unset;
          padding: 2rem 2rem 1rem;
        }

        .image-block {
          margin: 1rem 2rem;

          svg {
            width: 200px;
          }
        }
      }
    }

    .product-images__content {
      .images-columns-4 {
        grid-template-columns: repeat(2, 1fr);
      }

      img {
        height: 240px;
      }
    }

    .cover-pdf__inner {
      padding: 20px 30px;

      .logo {
        width: 55%;
        height: auto;
      }

      .urge-number {
        width: 40%;
        height: auto;
      }
    }

    .affiliate-terms {
      &__content-desktop {
        display: none;
      }

      &__content-mobile {
        display: flex;
      }
    }

    .contact-us {
      padding: 3.5rem 1rem;
      margin-top: 25px;

      h3 {
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    .container {
      margin: 2rem auto;

      &::before {
        top: 40px;
        left: 16px;
        right: 16px;
        width: calc(100% - 32px);
        height: 650px;
        background-position: bottom;
      }
    }

    .hero {
      padding: 20px 10px 240px;

      h2 {
        word-wrap: break-word;
      }
    }

    .welcome {
      padding: 30px 20px 0;

      .flex-row {
        flex-direction: column;
      }

      .text-block {
        p {
          margin-bottom: 20px;
        }
      }

      .image-block {
        display: flex;
        justify-content: center;

        img {
          width: 80%;
          margin-top: 0;
        }
      }
    }

    .partner {
      padding: 50px 0;

      .ultimate-choice {
        .join-us {
          flex-direction: column;

          p {
            margin-left: 0;
            margin-top: 1rem;
            text-align: center;
          }
        }

        .ultimate-choice__item:not(.join-us) {
          padding: 20px 20px 20px 55px;

          .item-body:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .affiliate-links {
      padding: 1.5rem 1rem;

      .generate-form {
        width: calc(100% - 28px);
      }

      .generate-form__inner {
        width: calc(100% - 2rem);
        padding: 1.5rem 1rem;

        h3 {
          font-size: 24px;
        }

        .input-item {
          input {
            width: calc(100% - 42px);
          }
        }

        .generate-hoplink {
          width: calc(100% - 16px);
          font-size: 16px;
          margin: 0.5rem 0.5rem 1rem;
        }

        .btn-generate {
          font-size: 24px;
          padding: 1rem 0.25rem;
          border-radius: 8px;
        }
      }
    }

    .install-pixels {
      .image-block {
        width: 100%;
      }

      .text-block {
        margin: 0 1.5rem;
      }
    }

    .email-swipes {
      padding: 1.25rem 1rem;
      margin-bottom: 25px;

      &__header {
        .text-block {
          width: 100%;
          padding: 1rem 0 1rem;
        }

        .image-block {
          margin: 1rem auto;

          svg {
            width: 120px;
          }
        }
      }
    }

    .product-images {
      margin: 25px 0;

      &__header {
        margin: 0 auto 10px;
      }

      &__content {
        .images-columns-3,
        .images-columns-4 {
          grid-template-columns: 1fr;
        }

        img {
          height: 200px;
        }
      }
    }

    .cover-pdf {
      padding: 1rem;
      margin: 25px 0;

      &__inner {
        flex-direction: column;
        padding: 1rem;

        .logo {
          width: 80%;
          height: auto;
          margin-bottom: 1rem;
        }

        .urge-number {
          width: 60%;
          height: auto;
        }
      }
    }

    .affiliate-terms {
      margin: 25px 0;

      p {
        text-align: center;
      }

      &__header {
        margin-bottom: 10px;
      }

      .terms-item {
        flex-direction: column;
        margin-bottom: 10px;
        padding-right: 0;
      }

      .number-box {
        margin: 0 auto 12px;
      }
    }
  }
}
