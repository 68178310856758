header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1.5rem;

  .large-logo {
    display: block;

    svg {
      width: 240px;
      height: auto;
    }
  }

  .small-logo {
    display: none;

    svg {
      width: 80px;
      height: auto;
    }
  }

  .mobile-logo {
    display: none;

    svg {
      width: 160px;
      height: auto;
    }
  }

  &.small {
    margin: 1rem 1.5rem -4rem 1.5rem;

    & + .App__page {
      min-height: calc(100vh - 36px);
    }

    .large-logo {
      display: none;
    }

    .small-logo {
      display: block;
    }
  }

  .right-block {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .order-link {
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 124px;
    min-height: 32px;
    border-radius: 8px;
    padding: 16px 16px;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;

    &.show {
      display: flex;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-repeat: no-repeat;
      background-size: 50% 100%, 50% 100%;
      background-position: 0 0, 100% 100%;
      background-image: linear-gradient(#4f0098, #4f0098),
        linear-gradient(#bd35dc, #bd35dc);
      animation: border-rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 4px;
      top: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      background: #0e1131;
      border-radius: 6px;
    }

    a {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;
    }
  }

  .user-menu {
    position: relative;
    display: flex;
    margin: 0 0.5rem 0 2rem;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #3d1957;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 50%;
      border: 2px solid #1a1649;
      cursor: pointer;
      transition: all 0.1s ease-in;

      &:hover {
        border-color: #fff;
      }
    }

    &__dropdown {
      visibility: hidden;
      position: absolute;
      top: 48px;
      right: 0;
      z-index: 99;

      &.opened {
        visibility: visible;

        .dropdown-content {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .dropdown-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    .dropdown-content {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #1a1649;
      border-radius: 8px;
      padding: 4px 4px 8px;
      opacity: 0;
      transform: scale(0.5);
      transform-origin: top right;
      box-shadow: 1px 8px 12px 1px #0e083140;
      z-index: 9;
      transition: all 0.15s ease-in;
    }

    .dropdown-menu-top {
      padding: 8px 12px;
      margin-bottom: 8px;
      border-bottom: 1px solid #ffffff80;

      .email {
        color: grey;
        font-size: 13px;
        padding-top: 4px;
      }
    }

    .dropdown-menu-item {
      white-space: nowrap;
      padding: 8px 12px;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.1s ease-in;

      &:hover {
        background-color: #3d1957;
      }
    }
  }

  &.reading {
    .user-menu {
      display: none;
    }
  }

  @media (min-width: 481px) and (max-height: 720px) {
    margin: 1rem 1.5rem -2rem 1.5rem;
  }

  @media (max-width: 1280px) {
    .large-logo svg {
      width: 200px;
    }
  }

  @media (max-width: 1280px) and (max-height: 720px) {
    &:not(.small) {
      margin: 1rem 1.5rem -1rem 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;
    margin: 1rem 1rem 1rem 2rem !important;

    .large-logo {
      display: block !important;
    }

    .small-logo {
      display: none !important;
    }
  }

  @media (max-width: 1024px) and (max-height: 720px) {
    margin: 1rem 1rem 0.25rem 2rem !important;
  }

  @media (max-width: 480px) {
    margin: 1rem !important;

    .large-logo {
      display: none !important;
    }

    .mobile-logo {
      display: block !important;
    }

    .order-link {
      height: 40px;
      font-size: 16px;
      border-radius: 8px;
      padding: 4px 8px;

      &::after {
        left: 3px;
        top: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
      }
    }

    .user-menu {
      margin: 0 0 0 0.5rem;
    }
  }

  @media (max-width: 480px) and (max-height: 720px) {
    margin: 1rem 1rem 0.25rem 2rem !important;
  }
}

@-webkit-keyframes border-rotate {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes border-rotate {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
