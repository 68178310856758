.background-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
