.page-members {
  margin-top: 50px;

  .box__wrapper {
    padding: 2rem;
  }

  .table-wrapper {
    max-height: calc(100vh - 270px);
    overflow: auto;

    table {
      width: 100%;
    }

    .list-header-item {
      width: fit-content;
      background: #3d1957;
      padding: 6px 24px;
      margin: 0 auto 12px;
      border-radius: 8px;
    }

    tbody tr:nth-child(2n + 1) {
      background: #3d1957;
    }

    td {
      text-align: center;
      padding: 24px 8px;
    }

    .action-buttons {
      width: fit-content;
      margin: auto;

      .btn-delete {
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 1.25rem;
        border-radius: 8px;

        &:hover {
          box-shadow: 5px 5px 20px rgba(152, 58, 219, 0.55);
        }

        &.disabled {
          background: #4f009888;
          pointer-events: none;
        }
      }
    }
  }

  .members-list {
    display: none;
    flex-direction: column;
    max-height: calc(100vh - 320px);
    overflow: auto;

    .member-item {
      display: flex;
      flex-direction: column;
      background: #3d1957;
      padding: 1.5rem;
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 10px;

        .bold {
          font-weight: 500;
          margin-right: 8px;
        }
      }

      .btn-delete {
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 1.25rem;
        border-radius: 8px;

        &.disabled {
          background: #4f009888;
          pointer-events: none;
        }
      }
    }
  }

  .no-members {
    display: flex;
    justify-content: center;
    font-size: 20px;
    padding: 50px;
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .table-wrapper {
      table {
        display: none;
      }
    }

    .members-list {
      display: flex;
    }
  }

  @media (max-width: 480px) {
    .box__wrapper {
      padding: 1rem;
    }

    .members-list {
      max-height: calc(100vh - 300px);

      .member-item {
        padding: 1rem;
      }
    }
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d0f2ca0;

  .modal-box {
    background: #3d1957;
    padding: 35px 40px;
    margin: 1rem;
    border-radius: 12px;
    box-shadow: 1px 1px 5px rgba(152, 58, 219, 0.55);
    animation: blowUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    p {
      font-size: 20px;
      text-align: center;
      line-height: 1.6;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    .btn-yes,
    .btn-no {
      font-size: 18px;
      font-weight: 500;
      padding: 12px 32px;
      border-radius: 8px;
      margin: 0 12px;
      cursor: pointer;
    }

    .btn-yes:hover {
      box-shadow: 5px 5px 20px rgba(152, 58, 219, 0.55);
    }

    .btn-no:hover {
      background: #1a164988;
    }
  }

  @media (max-width: 768px) {
    .modal-box {
      padding: 25px 30px;
      border-radius: 8px;

      p {
        font-size: 16px;
      }
    }

    .modal-footer {
      margin-top: 1rem;

      .btn-yes,
      .btn-no {
        font-size: 16px;
        padding: 8px 24px;
        margin: 0 4px;
      }
    }
  }
}

@keyframes blowUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
