footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #989898;
  margin: 2rem 1rem 1.5rem;
  z-index: 1;

  p {
    color: inherit;
    font-size: 14px;
    line-height: 1.6;
  }

  a {
    color: inherit;
    font-size: 14px;
    line-height: 1.6;
    text-decoration: none;

    &:hover {
      color: #fff;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 3rem 0 1.5rem;

    p {
      text-align: center;
    }

    .footer-links {
      margin-top: 1.5rem;
    }
  }
}
