.destiny-number-report {
  width: fit-content;
  margin: 0 auto;
  opacity: 0;
  transition: all 1s ease-in;

  &.entered {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    transform: scale(0.25);
    transition: all 0.8s ease-out;
  }

  .box__wrapper {
    display: flex;
    flex-direction: column;
  }

  .delay1 {
    transition-delay: 0.5s !important;
  }
  .delay2 {
    transition-delay: 1s !important;
  }

  .alphabets-chart {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 5px;
    padding: 30px 40px;

    .square-piece {
      width: 70px;
      height: 70px;
      background-color: #1a1649;
      padding: 3px;
      border-radius: 10px;
      transition: all 0.5s ease-in;

      .piece-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 38px;
        font-weight: 500;
        border-radius: 8px;
        transition: all 0.5s ease-in;
      }
    }

    .number-piece {
      color: #fc54ff;

      &.active {
        .piece-inner {
          transition: none;
          animation: number-piece-active 0.4s linear;
        }
      }
    }

    .letter-piece {
      color: #fff;
      text-transform: uppercase;

      &.active {
        background: #826697;
      }

      &.selected {
        .piece-inner {
          background: linear-gradient(0deg, #4f0098 0%, #bd35dc 100%);
        }
      }
    }
  }

  .calculation-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0.5rem;

    .numbers-sum {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 740px;
      padding: 0 30px;
      margin-bottom: 0.5rem;
    }

    .letter-label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #3d1957;
      border-radius: 4px;
      transition: all 0.5s ease-in;

      &.display {
        opacity: 1;
      }
    }

    .symbol-label {
      width: 14px;
      text-align: center;
      transform-origin: left;
      transform: rotate(-90deg);
      transition: all 0.5s ease-in 0.5s;

      &.display {
        opacity: 1;
        transform: rotate(0deg);
      }
    }

    .letter-label,
    .symbol-label {
      font-size: 24px;
      margin: 0 4px 0.5rem;
      opacity: 0;
    }

    .single-digit {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      div:nth-child(6) {
        margin-left: 2rem;
      }

      .result-block {
        display: flex;

        .symbol-label {
          width: unset;
          font-size: 20px;
          padding-top: 4px;
          opacity: 0;
          transition-property: opacity;

          &.display {
            opacity: 1;
          }
        }

        .letter-label {
          background: linear-gradient(0deg, #4f0098 0%, #bd35dc 100%);
        }
      }
    }
  }

  @media (min-width: 1025px) and (max-height: 720px) {
    .alphabets-chart {
      padding: 20px 32px;

      .square-piece {
        width: 64px;
        height: 64px;
        border-radius: 8px;

        .piece-inner {
          font-size: 34px;
          border-radius: 6px;
        }
      }
    }

    .calculation-block {
      margin: 1rem 0 0;

      .letter-label {
        font-size: 22px;
      }

      .numbers-sum {
        max-width: 680px;
      }
    }
  }

  @media (max-width: 1024px) {
    .alphabets-chart {
      padding: 20px;

      .square-piece {
        width: 64px;
        height: 64px;
        border-radius: 8px;

        .piece-inner {
          font-size: 34px;
          border-radius: 6px;
        }
      }
    }

    .calculation-block {
      .letter-label {
        font-size: 22px;
      }

      .numbers-sum {
        max-width: 680px;
      }
    }
  }

  @media (max-width: 768px) {
    .box__wrapper {
      padding: 12px;
    }

    .alphabets-chart {
      width: fit-content;
      gap: 5px;
      padding: 12px;
      margin: auto;

      .square-piece {
        width: 50px;
        height: 50px;
        border-radius: 6px;

        .piece-inner {
          font-size: 28px;
          border-radius: 4px;
        }
      }
    }

    .calculation-block {
      margin: 1rem 0 0;

      .letter-label {
        width: 30px;
        height: 30px;
        font-size: 20px;
      }

      .result-block {
        .symbol-label {
          font-size: 18px !important;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .box__wrapper {
      padding: 8px;
    }

    .alphabets-chart {
      gap: 3px;
      padding: 8px;

      .square-piece {
        width: 36px;
        height: 36px;

        .piece-inner {
          font-size: 22px;
        }
      }
    }

    .calculation-block {
      .letter-label {
        width: 28px;
        height: 28px;
      }

      .result-block {
        .symbol-label {
          font-size: 16px !important;
        }
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
    margin-bottom: 8rem;

    .box__wrapper {
      padding: 6px;
      border-radius: 6px;
    }

    .alphabets-chart {
      padding: 6px;
      border-radius: 4px;

      .square-piece {
        width: 28px;
        height: 28px;

        .piece-inner {
          font-size: 18px;
        }
      }
    }

    .calculation-block {
      .numbers-sum {
        padding: 0 0.5rem;
      }

      .letter-label {
        width: 24px;
        height: 24px;
        font-size: 16px;
        line-height: 1;
        margin: 0 2px 8px;
      }

      .symbol-label {
        font-size: 20px;
        line-height: 1;
        margin: 0 2px 8px;
      }

      .result-block {
        .symbol-label {
          font-size: 14px !important;
        }
      }
    }
  }
}

@-webkit-keyframes number-piece-active {
  0% {
    color: #fc54ff;
    background: transparent;
  }
  100% {
    color: #fff;
    background: linear-gradient(0deg, #4f0098 0%, #bd35dc 100%);
  }
}
@keyframes number-piece-active {
  0% {
    color: #fc54ff;
    background: transparent;
  }
  100% {
    color: #fff;
    background: linear-gradient(0deg, #4f0098 0%, #bd35dc 100%);
  }
}

@-webkit-keyframes number-display {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes number-display {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes symbol-display {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes symbol-display {
  0% {
    opacity: 0;
    transform: rotate(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}
