.page-members-area {
  margin-top: 50px;

  .pdf-list-item {
    display: flex;
    padding: 1rem 0;
  }

  .pdf-cover {
    position: relative;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 4rem;

    > img {
      width: 100%;
      height: auto;
    }

    .pdf-cover-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 16px;
      left: 12px;
      right: 12px;
      bottom: 12px;
      padding: 0.75rem 0.5rem;
      border: 1px solid #fff;
      border-radius: 8px;

      .logo {
        position: absolute;
        top: -16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 50%;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .title {
        font-size: 24px;
        font-weight: 900;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        word-break: break-word;

        &.style-1 {
          text-shadow: -2px -2px 0 #eb28c0, 2px -2px 0 #eb28c0,
            -2px 2px 0 #eb28c0, 2px 2px 0 #eb28c0;
          padding-top: 1.25rem;
        }
        &.style-2 {
          font-size: 22.5px;
          font-weight: 600;
        }
        &.style-3 {
          color: #eb28c0;
          text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
            1px 1px 0 #fff;
        }
      }

      .pdf-cover-bottom {
        background: #78056de0;
        font-size: 11px;
        line-height: 1.3;
        text-align: center;
        border: 3px solid #420061;
        padding: 4px 8px;
        margin: auto 0 0;
      }
    }
  }

  .box__inner {
    flex: 1;
    padding: 50px;
    margin-left: 2rem;

    .btn-download,
    .btn-download a {
      width: fit-content;
      color: #fff;
      font-size: 26px;
      text-decoration: none;
      padding: 0.875rem 4rem;
    }
  }

  @media (max-width: 1200px) {
    .pdf-cover {
      margin: 0 1rem 0 2rem;
    }

    .box__inner {
      padding: 50px 40px;
    }
  }

  @media (max-width: 1024px) {
    .pdf-list-item {
      flex-direction: column;
      align-items: center;
    }

    .pdf-cover {
      margin: 0 2rem 2rem;
    }

    .box__inner {
      display: flex;
      justify-content: center;
      width: calc(100% - 80px);
      padding: 30px 40px;
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    .pdf-cover {
      margin: 0 2rem 2rem;
    }

    .box__inner {
      display: flex;
      justify-content: center;
      width: calc(100% - 80px);
      padding: 20px 20px;
      margin-left: 0;

      .btn-download {
        padding: 0.875rem 3rem;
      }
    }
  }

  @media (max-width: 480px) {
    .pdf-cover {
      width: calc(100% - 2rem);
      max-width: 300px;
      margin: 0 1rem 1.5rem;
    }

    .box__inner {
      display: flex;
      justify-content: center;
      width: calc(100% - 1.5rem);
      padding: 0.75rem;
      margin-left: 0;

      .btn-download,
      .btn-download a {
        width: 100%;
        font-size: 20px;
        padding: 0.875rem 0.5rem;
        border-radius: 8px;
      }
    }
  }
}

.page-order {
  width: 100%;
  max-width: 1120px;
  margin: auto;

  .box__wrapper {
    display: flex;
    flex-direction: column;
    background: #1a1649;
    padding: 0;
    border-radius: 12px;
  }

  .box__header {
    background: #fff;
    padding: 0.75rem 5rem;
    border-radius: 12px 12px 0 0;

    .header-desc {
      max-width: 640px;
      color: #1a1649;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.14;
      text-align: center;
      margin: 0 auto;

      .origin-price {
        color: #6900be;
        font-weight: 900;
      }

      .save-price {
        color: #d439f9;
        font-weight: 900;
        padding-left: 0.5rem;
      }
    }
  }

  .box__content {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
  }

  .content-section {
    display: flex;
    padding: 4px 0;

    .mobile-visible {
      display: none;
      width: 100%;
      margin-bottom: 2rem;
    }

    .pdf-cover {
      position: relative;
      width: 100%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      > img {
        width: 100%;
        height: auto;
      }

      .pdf-cover-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 16px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        padding: 0.75rem 0.5rem;
        border: 1px solid #fff;
        border-radius: 8px;

        .logo {
          position: absolute;
          top: -16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          background: #fff;
          border-radius: 50%;

          img {
            width: 32px;
            height: 32px;
          }
        }

        .title {
          font-size: 24px;
          font-weight: 900;
          line-height: 1.2;
          text-align: center;
          text-transform: uppercase;
          word-break: break-word;

          &.style-1 {
            text-shadow: -2px -2px 0 #eb28c0, 2px -2px 0 #eb28c0,
              -2px 2px 0 #eb28c0, 2px 2px 0 #eb28c0;
            padding-top: 1.25rem;
          }
          &.style-2 {
            font-size: 22.5px;
            font-weight: 600;
          }
          &.style-3 {
            color: #eb28c0;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
              1px 1px 0 #fff;
          }
        }

        .pdf-cover-bottom {
          background: #78056de0;
          font-size: 11px;
          line-height: 1.3;
          text-align: center;
          border: 3px solid #420061;
          padding: 4px 8px;
          margin: auto 0 0;
        }
      }
    }

    a.btn-order-now {
      color: #fff;
      font-size: 26px;
      text-decoration: none;
      padding: 0.875rem 8rem;
      -webkit-animation: horizontal-shaking 4s linear both infinite;
      animation: horizontal-shaking 4s linear both infinite;
    }

    .order-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 565px;
      margin-top: 0.5rem;

      .lock-desc {
        display: flex;
        align-items: center;
        padding: 1rem 0;

        img {
          width: 25px;
          height: auto;
        }

        p {
          padding-left: 0.5rem;
        }
      }

      .payment-logos {
        display: flex;

        img {
          height: 40px;
          margin: 0 0.75rem;
        }
      }

      .sub-title {
        max-width: 420px;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        padding: 1rem 0;
      }

      .refer-box {
        display: flex;
        align-items: center;
        background: #3d1957e0;
        padding: 0.5rem;
        border-radius: 12px;
        margin-top: 0.5rem;

        img {
          height: 120px;
        }

        p {
          font-size: 16px;
          line-height: 1.4;
          padding-left: 1.25rem;
          padding-right: 0.5rem;
        }
      }
    }
  }

  .footer-section {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    img {
      max-width: 90px;
    }

    p {
      font-size: 14px;
      line-height: 1.57;
      padding-left: 18px;
    }
  }

  @media (max-width: 1024px) {
    .box__header {
      padding: 0.75rem 1rem;
    }

    .content-section {
      flex-direction: column;
      align-items: center;

      .order-block {
        margin: 3rem 0 1rem;
      }
    }
  }

  @media (max-width: 640px) {
    .box__header {
      .header-desc {
        max-width: 500px;
        font-size: 20px;
        line-height: 1.2;
      }
    }

    .box__content {
      padding: 20px;
    }

    .content-section {
      a.btn-order-now {
        width: calc(100% - 2rem);
        max-width: 400px;
        font-size: 24px;
        padding: 0.875rem 1rem;
      }

      .order-block {
        margin: 2rem 0 0;
      }
    }
  }

  @media (max-width: 480px) {
    .content-section {
      .mobile-visible {
        display: block;

        a.btn-order-now {
          display: block;
        }
      }

      a.btn-order-now {
        display: none;
      }

      .order-block {
        .payment-logos img {
          margin: 0 0.5rem;
        }

        .refer-box {
          flex-direction: column;
          padding: 1rem;
          margin-top: 0.5rem;

          img {
            height: auto;
          }

          p {
            padding: 1rem 0 0;
            text-align: center;
          }
        }
      }
    }

    .footer-section {
      flex-direction: column;
      align-items: center;

      p {
        padding: 1rem 0 0;
        text-align: center;
      }
    }
  }

  @media (min-width: 1025px) and (max-height: 720px) {
    .box__header {
      padding: 0.5rem 5rem;

      .header-desc {
        max-width: 600px;
        font-size: 20px;
      }
    }

    .box__content {
      display: flex;
      flex-direction: column;
      padding: 16px 30px;
    }

    .content-section {
      align-items: center;
      padding: 0;
      margin: -0.5rem 0;

      .pdf-cover {
        transform: scale(0.9);
      }

      .order-block {
        max-width: 600px;
        margin-top: 0;

        a.btn-order-now {
          font-size: 24px;
          padding: 0.75rem 7rem;
          border-radius: 8px;
        }

        .lock-desc {
          padding: 0.75rem 0;

          img {
            width: 20px;
          }

          p {
            font-size: 14px;
          }
        }

        .payment-logos {
          img {
            height: 36px;
            margin: 0 0.5rem;
          }
        }

        .sub-title {
          max-width: 380px;
          font-size: 16px;
        }

        .refer-box {
          border-radius: 10px;
          margin-top: 0;

          img {
            height: 100px;
          }

          p {
            font-size: 14px;
            line-height: 1.4;
            padding-left: 1.25rem;
            padding-right: 0.5rem;
          }
        }
      }
    }

    .footer-section {
      margin-top: 0;
    }
  }
}

@-webkit-keyframes horizontal-shaking {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  84% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  86% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  88% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  92% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  94% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  96% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  98% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes horizontal-shaking {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  84% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  86% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  88% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  92% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  94% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  96% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  98% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
