.quiz-name {
  width: 100%;
  max-width: 1024px;
  margin: 5rem auto 0;
  opacity: 0;
  transform: scale(0.25);
  transform-origin: center;
  transition: all 0.5s ease-in;

  &.entered {
    opacity: 1;
    transform: scale(1);
  }

  .box__inner {
    padding-bottom: 30px;
  }

  .box__content {
    width: 100%;
    max-width: 860px;
    margin: auto;
  }

  .h2 {
    padding-left: 1.5rem;
  }

  .form-content {
    display: flex;
    flex-wrap: wrap;

    > *:not(:first-child) {
      margin-left: 1.25rem;
    }

    input {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .btn-start {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    font-size: 30px;
    padding: 0.5rem 3.5rem;
    border-radius: 10px;

    &:hover {
      box-shadow: 0px 5px 20px rgba(152, 58, 219, 0.55);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  @media (max-width: 900px) {
    .form-content {
      input {
        width: calc(100% - 415px);
      }
    }

    .btn-start {
      padding: 0.5rem 2rem;
    }
  }

  @media (max-width: 768px) {
    .h2 {
      font-size: 24px;
      text-align: center;
      padding-left: 0;
    }

    .form-content {
      input {
        width: calc(100% - 3.5rem);
        margin-bottom: 18px;
      }
    }

    .btn-start {
      width: calc(100% - 4rem);
      height: auto;
      font-size: 26px;
      padding: 1rem 2rem;
      margin-left: 0 !important;
    }
  }

  @media (max-width: 640px) {
    .h2 {
      font-size: 20px;
    }

    .form-content {
      flex-direction: column;

      > *:not(:first-child) {
        margin-left: 0;
      }

      input {
        width: auto;
      }
    }

    .btn-start {
      width: calc(100% - 2rem);
      font-size: 22px;
      padding: 0.75rem 1rem;
    }
  }
}
