.numerology-aspects {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: all 1s ease-in;

  &.display {
    opacity: 1;
  }

  .h2 {
    text-align: center;
  }

  .numbers-image {
    opacity: 1 !important;
  }

  .image-container {
    position: absolute;
    top: 70px;
    width: 380px;
    height: 380px;
    opacity: 0;

    &.display {
      -webkit-animation: numerology-aspect-image-switch 2.5s
        cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: numerology-aspect-image-switch 2.5s
        cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    img {
      width: 100%;
      height: auto;
    }

    &:nth-child(3) {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }
    &:nth-child(5) {
      -webkit-animation-delay: 6s;
      animation-delay: 6s;
    }
    &:nth-child(6) {
      -webkit-animation-delay: 8s;
      animation-delay: 8s;
    }
    &:nth-child(7) {
      -webkit-animation-delay: 10s;
      animation-delay: 10s;
    }
    &:nth-child(8) {
      -webkit-animation-delay: 12s;
      animation-delay: 12s;
    }
    &:nth-child(9) {
      -webkit-animation-delay: 14s;
      animation-delay: 14s;
    }

    @media (max-width: 480px) {
      top: 80px;
      width: calc(100vw - 4rem);
      max-width: 280px;
      height: calc(100vw - 4rem);
      max-height: 280px;
    }
  }

  @media (max-width: 480px) {
    min-height: 100vw;
    margin-bottom: 5rem;
  }

  @media (min-width: 481px) and (max-height: 720px) {
    padding-top: 0;

    .image-container {
      top: 60px;
      width: 340px;
      height: 340px;
    }
  }
}

.life-path-number-calculation {
  display: flex;
  -webkit-animation: container-enter 2s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: container-enter 2s cubic-bezier(0.39, 0.575, 0.565, 1);
  max-width: 1280px;
  margin: 0 auto;

  &.hidden {
    opacity: 0;
    transform: scale(0.25);
    transition: all 0.8s ease-out;
  }

  .box__inner {
    width: 100%;
    display: flex;
    padding: 24px 32px;
  }

  .animation-block {
    display: flex;
    flex-direction: column;
    background: #1a1649;
    border-radius: 8px;
    padding: 24px 32px;

    .upper {
      display: flex;
      padding-right: 1rem;

      .birth {
        width: fit-content;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #826697;
        padding: 10px;
        border-radius: 12px;
        -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
          both;
        animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both;

        &-item {
          font-size: 40px;
          font-weight: 600;
          text-align: center;
          padding: 1rem 1.5rem;
          border-radius: 8px;
          -webkit-animation: scale-up-tl 0.3s
            cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: scale-up-tl 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          transition: background 0.3s ease-in;

          &.active {
            background: #3d1957;
          }

          &:nth-child(1) {
            -webkit-animation-delay: 3.4s;
            animation-delay: 3.4s;
          }
          &:nth-child(2) {
            -webkit-animation-delay: 3.6s;
            animation-delay: 3.6s;
          }
          &:nth-child(3) {
            -webkit-animation-delay: 3.8s;
            animation-delay: 3.8s;
          }
        }
      }

      .numerology {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0 0.5rem 3.5rem;

        &-item {
          display: flex;
          flex-direction: column;
        }

        .animate-numerology-item {
          position: relative;
          display: flex;
          font-size: 40px;

          .letters {
            display: flex;
            margin-right: auto;

            .plus-between-nums {
              padding: 0 0.125rem;
            }

            .th {
              font-size: 18px;
              line-height: 1.3;
              padding-top: 6px;
              padding-right: 8px;
            }
          }

          &.year-item {
            .letters {
              .num {
                -webkit-animation: opacity-visible 0.2s linear both;
                animation: opacity-visible 0.2s linear both;

                &:nth-child(1) {
                  -webkit-animation-delay: 5s;
                  animation-delay: 5s;
                }
                &:nth-child(3) {
                  -webkit-animation-delay: 5.15s;
                  animation-delay: 5.15s;
                }
                &:nth-child(5) {
                  -webkit-animation-delay: 5.3s;
                  animation-delay: 5.3s;
                }
                &:nth-child(7) {
                  -webkit-animation-delay: 5.45s;
                  animation-delay: 5.45s;
                }
              }

              .plus-between-nums {
                -webkit-animation: translate-down 0.2s linear both;
                animation: translate-down 0.2s linear both;

                &:nth-child(2) {
                  -webkit-animation-delay: 5.8s;
                  animation-delay: 5.8s;
                }
                &:nth-child(4) {
                  -webkit-animation-delay: 5.9s;
                  animation-delay: 5.9s;
                }
                &:nth-child(6) {
                  -webkit-animation-delay: 6s;
                  animation-delay: 6s;
                }
              }
            }

            .equal-to {
              -webkit-animation: opacity-visible 0.2s linear 6.5s both;
              animation: opacity-visible 0.2s linear 6.5s both;
            }

            .result-sum {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 7s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 7s both;
            }

            .single-num {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 7.5s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 7.5s both;
            }
          }

          &.month-item {
            display: flex;

            .letters {
              margin-right: 0;
              -webkit-animation: opacity-visible 0.5s linear 8.5s both;
              animation: opacity-visible 0.5s linear 8.5s both;
            }

            .equal-to {
              -webkit-animation: opacity-visible 0.2s linear 9.3s both;
              animation: opacity-visible 0.2s linear 9.3s both;
            }

            .result-sum {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 9.8s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 9.8s both;
            }
          }

          &.day-item {
            .letters {
              .num {
                -webkit-animation: opacity-visible 0.2s linear both;
                animation: opacity-visible 0.2s linear both;

                &:nth-child(1) {
                  -webkit-animation-delay: 10.8s;
                  animation-delay: 10.8s;
                }
                &:nth-child(3) {
                  -webkit-animation-delay: 10.95s;
                  animation-delay: 10.95s;
                }
              }

              .plus-between-nums {
                -webkit-animation: translate-down 0.2s linear both;
                animation: translate-down 0.2s linear both;

                &:nth-child(2) {
                  -webkit-animation-delay: 11.5s;
                  animation-delay: 11.5s;
                }
              }
            }

            .equal-to {
              -webkit-animation: opacity-visible 0.2s linear 12s both;
              animation: opacity-visible 0.2s linear 12s both;
            }

            .result-sum {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 12.5s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 12.5s both;
            }
          }

          &.total-item {
            display: flex;

            .letters {
              -webkit-animation: opacity-visible 0.5s linear 13.8s both;
              animation: opacity-visible 0.5s linear 13.8s both;
            }

            .equal-to {
              -webkit-animation: opacity-visible 0.2s linear 14.6s both;
              animation: opacity-visible 0.2s linear 14.6s both;
            }

            .result-sum {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 15.1s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 15.1s both;
            }
          }
        }

        .equal-to {
          margin: 0 1rem 0 1.5rem;
        }

        .result-sum {
          min-width: 55px;
          color: #b735eb;
          text-align: center;
        }

        .single-num {
          position: absolute;
          right: 0;
          background-color: #1a1649;
        }

        .total-top-line {
          width: 65%;
          height: 3px;
          background: #fff;
          -webkit-animation: scale-up-right 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) 13s both;
          animation: scale-up-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            13s both;
        }

        .plus-between-items {
          width: 55px;
          color: #b735eb;
          font-size: 40px;
          line-height: 0.75;
          text-align: center;
          margin-left: auto;
        }

        &-item:nth-child(1) {
          .plus-between-items {
            -webkit-animation: rotate-slide-right 0.2s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) 8s both;
            animation: rotate-slide-right 0.2s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) 8s both;
          }
        }
        &-item:nth-child(2) {
          .plus-between-items {
            -webkit-animation: rotate-slide-right 0.2s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) 10.3s both;
            animation: rotate-slide-right 0.2s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) 10.3s both;
          }
        }
      }
    }

    .lower {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3d1957;
      font-size: 40px;
      line-height: 1;
      padding: 1.75rem 0;
      margin-top: 24px;
      border-radius: 8px;
      -webkit-animation: scale-up-top 0.5s cubic-bezier(0.39, 0.575, 0.565, 1)
        16s both;
      animation: scale-up-top 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 16s both;

      .then {
        margin-right: 12px;
        -webkit-animation: opacity-visible 0.2s linear 16.8s both;
        animation: opacity-visible 0.2s linear 16.8s both;
      }

      .numerology {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &.animate {
          .animate-numerology-item:nth-child(1) {
            position: absolute;
            opacity: 0;
            transform: translateY(-100%);
            -webkit-animation: translate-up 2.4s linear 17s;
            animation: translate-up 2.4s linear 17s;
          }
        }

        .animate-numerology-item {
          display: flex;

          &:nth-child(1) {
            .num {
              &:nth-child(1) {
                -webkit-animation: opacity-visible 0.2s linear 17.2s both;
                animation: opacity-visible 0.2s linear 17.2s both;
              }
              &:nth-child(3) {
                -webkit-animation: opacity-visible 0.2s linear 17.35s both;
                animation: opacity-visible 0.2s linear 17.35s both;
              }
            }

            .plus {
              margin: 0 4px;
              -webkit-animation: translate-down 0.2s linear 17.7s both;
              animation: translate-down 0.2s linear 17.7s both;
            }

            .equal-to {
              margin: 0 16px;
              -webkit-animation: opacity-visible 0.2s linear 18.2s both;
              animation: opacity-visible 0.2s linear 18.2s both;
            }

            .result {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 18.5s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 18.5s both;

              &:nth-child(1) {
                margin: 0 1rem;
                -webkit-animation: scale-down-center 0.2s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94) 17.5s both;
                animation: scale-down-center 0.2s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94) 17.5s both;
              }
            }
          }
          &:nth-child(2) {
            padding-right: 2rem;

            .num {
              &:nth-child(1) {
                -webkit-animation: opacity-visible 0.2s linear 19.4s both;
                animation: opacity-visible 0.2s linear 19.4s both;
              }
              &:nth-child(3) {
                -webkit-animation: opacity-visible 0.2s linear 19.55s both;
                animation: opacity-visible 0.2s linear 19.55s both;
              }
            }

            .plus {
              margin: 0 4px;
              -webkit-animation: translate-down 0.2s linear 19.9s both;
              animation: translate-down 0.2s linear 19.9s both;
            }

            .equal-to {
              margin: 0 16px;
              -webkit-animation: opacity-visible 0.2s linear 20.4s both;
              animation: opacity-visible 0.2s linear 20.4s both;
            }

            .result {
              -webkit-animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 20.7s both;
              animation: scale-down-center 0.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) 20.7s both;
            }
          }
        }
      }
    }
  }

  .result-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 1.5rem;
    margin-left: 2rem;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      19.5s both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 19.5s
      both;

    &.delay {
      -webkit-animation-delay: 21.9s;
      animation-delay: 21.9s;
    }

    .life-path-number-box {
      height: calc(100% - 0.5rem);
      background: #c6acda;
      border-radius: 12px;
      padding: 0.25rem;

      &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100% - 1.75rem);
        background: #fff;
        border-radius: 10px;
        padding: 0.75rem 1.25rem 1rem;

        .result-number {
          color: #e160ff;
          font-size: 140px;
          font-weight: 600;
          line-height: 1;
          margin: 2rem 0;
        }

        .name-text {
          font-size: 40px;
        }

        p {
          color: #1a1649;
          font-size: 30px;
          font-weight: 600;
          line-height: 1.4;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .animation-block {
      padding: 24px 28px;

      .upper {
        padding-right: 0;

        .birth {
          &-item {
            font-size: 38px;
          }
        }

        .numerology {
          margin: 0.5rem 0 0.5rem 3rem;
        }
      }
    }

    .result-block {
      padding: 0;
      margin-left: 2.5rem;

      .life-path-number-box {
        &__inner {
          padding: 0.75rem 0.5rem 1rem;

          .result-number {
            font-size: 130px;
          }

          .name-text {
            font-size: 38px;
          }

          p {
            font-size: 28px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .animation-block {
      padding: 30px;

      .upper {
        .birth {
          &-item {
            font-size: 32px;
          }
        }

        .numerology {
          margin: 0.5rem 0 0.5rem 2rem;

          .animate-numerology-item {
            font-size: 34px;

            .letters {
              .th {
                font-size: 16px;
              }
            }
          }

          .equal-to {
            margin: 0 0.75rem 0 1rem;
          }

          .result-sum {
            min-width: 50px;
          }

          .plus-between-items {
            width: 50px;
          }
        }
      }

      .lower {
        font-size: 36px;
      }
    }

    .result-block {
      padding: 0;
      margin-left: 2rem;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 640px;
    margin: 0 auto 4rem;

    .box__inner {
      flex-direction: column;
    }

    .animation-block {
      .upper {
        .numerology {
          margin: 0.5rem 1rem 0.5rem auto;
        }
      }

      .lower {
        padding: 1.5rem 0;
      }
    }

    .result-block {
      width: 100%;
      margin: 2rem auto 0;

      .life-path-number-box {
        height: auto;

        .result-number {
          font-size: 100px;
          margin: 0.5rem 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: auto;

    .box__inner {
      width: calc(100% - 3rem);
    }

    .animation-block {
      padding: 1rem;

      .upper {
        .birth {
          border-radius: 8px;

          &-item {
            font-size: 30px;
            padding: 0.75rem;
          }
        }

        .numerology {
          margin: 0.5rem 0.5rem 0.5rem 2rem;

          .animate-numerology-item {
            font-size: 30px;

            .letters {
              .th {
                font-size: 15px;
                padding-top: 4px;
              }
            }
          }

          .plus-between-items {
            font-size: 30px;
          }

          .equal-to {
            margin: 0 0.25rem 0 0.5rem;
          }

          .result-sum {
            min-width: 45px;
          }

          .plus-between-items {
            width: 45px;
          }
        }
      }

      .lower {
        font-size: 30px;
        padding: 1.25rem 0;
        margin-top: 20px;
      }
    }

    .result-block {
      margin: 1.25rem auto 0;
    }
  }

  @media (max-width: 640px) {
    &.box__wrapper {
      width: calc(100% - 2rem);
    }

    .box__inner {
      width: 100%;
      padding: 1rem;
    }

    .animation-block {
      background: transparent;
      padding: 0;

      .upper {
        flex-direction: column;

        .birth {
          flex-direction: row;
          width: calc(100% - 1rem);
          gap: 0.25rem;
          padding: 0.5rem;

          &-item {
            font-size: 26px;
            font-weight: 500;
            padding: 0.5rem 0.75rem;

            &.active {
              flex: 1;
            }
          }
        }

        .numerology {
          margin: 1rem auto 0;

          .total-top-line {
            width: 70%;
            height: 2px;
            margin: 0.25rem 0;
          }

          .animate-numerology-item {
            font-size: 26px;

            .letters {
              margin-right: auto;
              .th {
                font-size: 14px;
                padding-top: 2px;
              }

              .plus-between-nums {
                padding: 0 0.5rem;
              }
            }

            &.month-item {
              .equal-to {
                margin-left: auto;
              }
            }
          }

          .plus-between-items {
            font-size: 26px;
          }

          .equal-to {
            margin: 0 0.25rem 0 1rem;
          }
        }
      }

      .lower {
        background: #1a1649;
        font-size: 26px;
        padding: 1rem 0;
        margin-top: 1rem;
      }
    }

    .result-block {
      margin: 0 auto 0;

      .life-path-number-box {
        margin-top: 1rem;

        &__inner {
          .result-number {
            font-size: 80px;
          }

          .name-text {
            font-size: 28px;
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (min-width: 1025px) and (max-height: 720px) {
    margin: 0 auto;

    .box__inner {
      padding: 20px 28px;
    }

    .animation-block {
      padding: 20px 30px;

      .upper {
        .birth {
          &-item {
            font-size: 32px;
          }
        }

        .numerology {
          margin: 0.5rem 0 0.5rem 2rem;

          .animate-numerology-item {
            font-size: 34px;

            .letters {
              .th {
                font-size: 16px;
              }
            }
          }

          .equal-to {
            margin: 0 0.75rem 0 1rem;
          }

          .result-sum {
            min-width: 50px;
          }

          .plus-between-items {
            width: 50px;
          }
        }
      }

      .lower {
        font-size: 36px;
        padding: 1.5rem 0;
        margin-top: 20px;
      }
    }

    .result-block {
      padding: 0;
      margin-left: 2rem;
    }
  }
}

@-webkit-keyframes numerology-aspect-image-switch {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes numerology-aspect-image-switch {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes container-enter {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes container-enter {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes translate-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  85% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes translate-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  85% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
