.year-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1.125rem;
  row-gap: 1.25rem;
  width: fit-content;
  margin: 0 auto;

  .number-box {
    width: 188px;
  }

  @media (max-width: 1440px) {
    .number-box {
      width: 155px;
    }
  }

  @media (max-width: 1024px) {
    .number-box {
      width: calc((100vw - 220px) / 6 - 8px);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    width: auto;
    margin: 0;

    .number-box {
      width: auto;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    column-gap: 0.75rem;
    row-gap: 0.75rem;

    .number-box:nth-child(10) {
      grid-column-start: 2;
    }
  }
}
