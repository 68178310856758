.days-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1rem;
  row-gap: 1.25rem;

  .number-box {
    width: calc((100% - 15rem) / 16 - 0.5rem);

    &:nth-child(1) {
      margin-left: 2rem;
    }
    &:nth-child(15) {
      margin-right: 2rem;
    }
  }

  @media (max-width: 1440px) {
    column-gap: 0.75rem;

    .number-box {
      width: calc((100% - 11.25rem) / 16 - 0.5rem);
    }
  }

  @media (max-width: 1280px) {
    row-gap: 1rem;

    .number-box {
      &:nth-child(1) {
        margin-left: 0.5rem;
      }
      &:nth-child(15) {
        margin-right: 0.5rem;
      }
    }
  }

  @media (max-width: 1024px) {
    width: fit-content;
    margin: 0 auto;

    .number-box {
      width: 50px;

      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-child(15) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    column-gap: 0.5rem;

    .number-box {
      width: 42px;
    }
  }

  @media (max-width: 480px) {
    .number-box {
      width: 36px;
    }
  }
}
