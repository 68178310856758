.month-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.125rem;
  row-gap: 1.25rem;

  .number-box {
    &__inner {
      font-size: 22px;
    }
  }

  @media (max-width: 1280px) {
    column-gap: 1rem;
    row-gap: 1rem;

    .number-box {
      &__inner {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    width: fit-content;
    margin: 0 auto;

    .number-box {
      width: calc((100vw - 188px) / 4 - 8px);
      max-width: 150px;
    }
  }

  @media (max-width: 768px) {
    width: auto;
    margin: 0;

    .number-box {
      width: auto;
      max-width: unset;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75rem;
    row-gap: 0.75rem;
  }
}
