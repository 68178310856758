@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Pelmeshka';
  src: url('../assets/fonts/Pelmeshka/Pelmeshka.ttf');
}

body {
  background: #0d0f2c;
  color: #fff;
  font-family: 'Montserrat';
  margin: 0;
}

h1,
h2,
p {
  margin: 0;
}

.App {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 1600px;
    background-image: url('../assets/images/home-background.png');
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 1s ease-out;
  }

  &__page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 1400px;
    min-height: calc(100vh - 118px);
    margin: 0 auto;
    overflow: hidden;

    @media (min-width: 481px) and (max-height: 720px) {
      min-height: calc(100vh - 74px);
    }

    @media (max-width: 1440px) {
      max-width: 1200px;
    }

    @media (max-width: 1280px) {
      width: calc(100% - 3rem);
      min-height: calc(100vh - 105px);
      margin: 0 1.5rem;
    }

    @media (max-width: 1280px) and (max-height: 720px) {
      min-height: calc(100vh - 74px) !important;
    }

    @media (max-width: 1024px) and (max-height: 720px) {
      min-height: calc(100vh - 93px) !important;
    }

    @media (max-width: 768px) {
      width: calc(100% - 2rem);
      margin: 0 1rem;
    }

    @media (max-width: 480px) {
      min-height: calc(100vh - 64px) !important;
    }
  }

  .clickbank-note {
    position: relative;
    width: 100%;
    max-width: 1400px;
    color: #989898;
    font-size: 14px;
    line-height: 1.6;
    margin: 0 auto 2rem;
    z-index: 9;

    p {
      padding: 0 1rem;
    }

    @media (max-width: 1440px) {
      max-width: 1200px;
    }

    @media (max-width: 1280px) {
      width: calc(100% - 3rem);
    }

    @media (max-width: 768px) {
      width: calc(100% - 2rem);
    }

    @media (max-width: 480px) {
      p {
        text-align: center;
      }
    }
  }
}

// Typography
.h1 {
  font-size: 40px;
  line-height: 46px;
}

.h2 {
  font-size: 30px;
  line-height: 1.4;
}

.h3 {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}

//
.text-center {
  text-align: center;
}

// buttons
.btn-gradient {
  background: linear-gradient(90.51deg, #4f0098 0.44%, #bd35dc 99.57%);
  font-size: 32px;
  text-align: center;
  padding: 1.25rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: linear-gradient(1.04deg, #8538cc 0.99%, #e160ff 99.2%);
    box-shadow: 0px 12px 55px rgba(152, 58, 219, 0.55);
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

// inputs
input,
select {
  background: #826697;
  color: #fff;
  font-size: 18px;
  line-height: 1.7;
  padding: 0.875rem 1.75rem;
  margin-bottom: 18px;
  border-radius: 10px;
  border: 1px solid transparent;
  outline: none;

  &::placeholder {
    color: #bca2cf;
  }

  &.error {
    border-color: #ff0000;
  }
}

@media (max-width: 640px) {
  input,
  select {
    font-size: 16px;
    padding: 0.625rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 8px;
  }
}

// layouts
.box {
  &__wrapper {
    background: #1a1649;
    border-radius: 12px;
    padding: 1rem;
  }

  &__inner {
    background: #3d1957;
    padding: 30px 50px 40px;
    border-radius: 8px;

    .h2 {
      margin-bottom: 1.125rem;
    }
  }
}

.number-box {
  background: #826697;
  padding: 0.25rem;
  border-radius: 12px;
  transition: all 0.2s ease-in;

  &__inner {
    background: linear-gradient(1.04deg, #4f0098 0.99%, #bd35dc 99.2%);
    font-size: 26px;
    line-height: 26px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }

  &:hover {
    background: #fff;
    box-shadow: 0px 6px 33px rgba(210, 58, 247, 0.55);

    .number-box__inner {
      background: linear-gradient(1.04deg, #8538cc 0.99%, #e160ff 99.2%);
    }
  }
}

@media (max-width: 1280px) {
  .h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .h2 {
    font-size: 28px;
  }

  .box {
    &__inner {
      padding: 30px;
    }
  }

  .number-box {
    padding: 0.25rem;
    border-radius: 10px;

    &__inner {
      font-size: 22px;
      line-height: 1;
      border-radius: 7px;
    }
  }
}

@media (max-width: 768px) {
  .h1 {
    font-size: 32px;
    line-height: 36px;
  }

  .h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .box {
    &__inner {
      padding: 1.5rem;
    }
  }
}

@media (max-width: 480px) {
  .h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .h2 {
    font-size: 22px;
  }

  .h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .box {
    &__inner {
      padding: 1.5rem;
    }
  }

  .number-box {
    padding: 0.25rem;
    border-radius: 10px;

    &__inner {
      font-size: 20px;
    }
  }
}

@media (min-width: 1025px) and (max-height: 720px) {
  .h2 {
    font-size: 28px;
  }
}

@-webkit-keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-up-tl {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
